.account {
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	border-radius: 10px;
	background-color: 0;
	/* transition-duration: 0.2s; */
}

.account:not(.active):hover {
	background-color: #0000003d;
	transition-duration: 0.5s;
}

.account-image {
	border-radius: 100px;
	padding: 45px;
	max-width: 45px;
	margin-left: auto;
	margin-right: auto;
	aspect-ratio: 1;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition-duration: 0.5s;
	box-shadow: inset 0px 0px 5px 0px #0000009e;
	position: relative;
	overflow: hidden;
}

.account-company-logo {
	background-color: #00000060;
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: center;
	backdrop-filter: blur(3px);
	transition-duration: 0.3s;
	opacity: 0;
}

.account:not(.active):hover .account-company-logo {
	opacity: 1;
}

.account-infos {
	margin-top: 5px;
	line-height: 1rem;
	font-size: 0.875rem;
}

.delete-account {
	opacity: 0;
	transition-duration: 0.3s;
	cursor: pointer;
	overflow: hidden;
	max-width: 0px;
	position: absolute;
	right: 5px;
	top: 3px;
	font-size: 0.875rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.delete-account .delete-icon {
	color: #f82626;
}

.delete-account .delete-msg {
	display: none;
}

.account:not(.active):hover .delete-account {
	opacity: 1;
	max-width: 200px;
}

.account.active .delete-account {
	display: none;
}

.account.active {
	pointer-events: none;
}

@media (max-width: 770px) {
	.account-item-anim {
		width: 100%;
	}

	.account:not(.active) {
		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-direction: row;
	}
	.account:not(.active) > a {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.account:not(.active) .account-image {
		padding: 30px;
		margin-left: 5px;
		margin-right: 5px;
		margin-bottom: 0px;
		box-shadow: inset 0px 0px 15px -5px #0000009e;
		transition-duration: 0.1s;
	}

	.account:not(.active) .account-infos {
		width: auto;
		display: block;
		height: auto;
		line-height: 0.9375rem;
		margin-top: 0px;
		color: white;
		font-size: 0.875rem;
		margin-left: 20px;
		text-align: left;
	}

	.delete-account {
		border-top: none;
		max-height: 0;
		max-width: initial;
		min-width: 100px;
		position: unset;
	}

	.delete-account .delete-msg {
		display: block;
	}

	.delete-account:hover {
		background-color: #bb000061;
		border-radius: 6px;
		padding: 4px;
	}

	.account:not(.active):hover .delete-account {
		opacity: 1;
		max-height: 50px;
		max-width: unset;
	}
}
