.sel-posts-state-menu {
	/* background-color: var(--background-color-secondary); */
	display: flex;
	justify-content: space-between;
	border-radius: 6px;
}

.sel-state-btn {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	padding: 5px;
	text-align: center;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 0.75rem;
}

.sel-state-btn:not(.active):hover {
	cursor: pointer;
	background-color: rgba(var(--color-primary-rgb), 0.6);
}

.sel-state-btn.active {
	color: white;
	background-color: var(--color-primary);
}

@media screen and (max-width: 700px) {
	.sel-posts-state-menu {
		width: 90%;
		margin: 0 auto;
	}
	.sel-posts-state-menu .Button {
		font-size: 0.7rem;
	}
}
