.input-range__track--active {
	background: var(--color-primary);
}

.input-range__slider {
	background-color: var(--color-primary);
	border: 1px solid var(--color-primary);
}


.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(var(--color-primary-rgb), 0.2);
}

.input-range__label--value {
	font-family: "Poppins-Bold";
	color: var(--font-color);
}

.input-range__label {
	font-family: "Poppins-Regular";
}