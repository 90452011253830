
/* BODY */
.Table .MuiTableBody-root {
	height: 100%;
}

.Table .MuiTableBody-root tr
{
    transition-duration: 0.3s;
	background-color: var(--background-color-secondary);
	cursor: pointer;
}

.dark-bg .Table .MuiTableBody-root tr,
.light-bg .Table .MuiTableBody-root tr {
	background-color: var(--background-color);
}

.Table .MuiTableBody-root tr:hover
{
    background-color: rgb(56, 164, 223);
	background-color: rgba(var(--color-primary-rgb), 0.5);
    transition-duration: 0.1s;
	color: var(--color-primary-font-color);
}

.Table .MuiTableBody-root tr td:first-child
{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.Table .MuiTableBody-root tr td:last-child
{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.Table .MuiTableBody-root tr td {
	padding: 0.5em;
	line-height: 14px;
}

/* checkbox */
.Table .MuiCheckbox-root {
	padding: 0;
}

.Table tr.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}


.Table tr.active .MuiCheckbox-root input:checked:after {
	border: 1px solid var(--background-color-secondary);
}

[class*=MTablePaginationInner] {
	color: var(--font-color)!important;
}

.MuiSvgIcon-root:not(.MuiTableSortLabel-icon) {
	color: var(--font-color)!important;
}

.MuiCheckbox-root .MuiSvgIcon-root {
	color: var(--color-primary)!important;
}
