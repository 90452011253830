.OnlineUsers {
	padding: 12px;
	border-radius: 12px;
	background-color: var(--background-color-secondary);
}

.OnlineUsers .head-btns {
	display: flex;
	align-items: center;
	background-color: var(--background-color);
	border-radius: 6px;
	overflow: hidden;
}

.rounded-buttons .OnlineUsers .head-btns {
	border-radius: 50px;
}

.OnlineUsers .head-btns > button {
	padding: 5px 10px;
	width: 100%;
}

.OnlineUsers .head-btns > button:not(:last-child)
{
	border-right: 1px solid var(--background-color-secondary);
}

.OnlineUsers .head-btns > button.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.OnlineUsers .list-ph {
	color: grey;
	text-align: center;
}

.OnlineUsersList {
	display: flex;
	flex-direction: column;
	max-height: 30vh;
	overflow: auto;
}

/*
** -- UserTiles
*/

.UserTile {
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 5px;
	font-size: 0.875rem;
}

.UserTile .user-preview .user-infos {
	font-size: 0.875rem;
}

.UserTile:not(:last-child)
{
	margin-bottom: 0.5rem;
}

.UserTile .last-conn {
	font-size: 0.75rem;
	color: grey;
}

.UserTile .online-dot {
	width: 10px;
	height: 10px;
	background-color: rgb(0, 169, 0);
	border-radius: 50%;
}
