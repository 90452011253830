.Assignator {
	display: flex;
    flex-direction: column;
    overflow: hidden;
	height: 100%;
}


.RowAdder {
	background-color: var(--background-color-secondary);
}

.AssignRow.RowAdder .row-head {
    background-color: var(--background-color-secondary);
	color: var(--font-color);
}

.AssignRow.RowAdder .row-type {
	margin: 0px 5px;
}

.AssignRow.RowAdder .row-body .row-buttons {
	display: flex;
}

.Assignator .AssignationList {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.Assignator > .AssignationList {
    overflow: auto;
    height: 100%;
	border-radius: 12px;
}
