.default-display .menu-nav {
	padding: 0.5rem 0px!important;
	justify-content: flex-start;
}

.default-display .PageButton .nav-link {
	color: #4b4b4b;
}

.theme-dark .default-display .PageButton .nav-link {
	color: var(--font-color);
}

.Menu.default-display .PagesGroup.open>.pages-group
{
	align-items: unset;
	padding-left: 30px;
	margin-left: 25px;
	margin-top: 0;
	padding-top: 10px;
	padding-bottom: 5px;
	border-bottom-left-radius: 3px;
}

.Menu.default-display .PagesGroup.active.open>.pages-group {
	background-color: var(--background-color);
}

.default-display .menu-links {
	width: calc(100% + 8px);
	padding: 0px!important;
	gap: 0.75rem;
	margin-bottom: 2rem;
}

.default-display .PagesGroup .menu-wire {
	display: none;
}

.default-display .PageButton {
	width: 100%;
	border: 0px solid transparent;
	border-left: 10px solid transparent;
	background-color: transparent;
	padding: 5px 0px;
	padding-right: 10px;
}

.default-display .PageButton.active {
	background-color: unset;
	border: none;
	border-left: 10px solid var(--color-primary);
	background-color: var(--background-color);
	color: var(--font-color);
	font-family: "Poppins-Bold";
}

.theme-dark .default-display .PageButton.PageButton.active {
	background-color: var(--background-color);
}

.default-display .PageButton.outlined,
.default-display .PageButton:not(.active):hover	
{
	border-width: 0px;
	border-left: 2px solid var(--color-primary);
	transition-duration: 0.5s;
}

.default-display .PageButton .nav-link:focus-visible {
    border: 2px solid var(--color-primary);
    outline: none;
}

.default-display .PagesGroup.open>.pages-group {
	background-color: unset;
}

.theme-dark .default-display .PagesGroup.active.open>.pages-group {
	align-items: unset;
	background-color: var(--background-color);
}

.default-display .PagesGroup .pages-group .PageButton {
	border-left-width: 2px;
	padding: 0px;
	padding-right: 10px;
}

.default-display .PagesGroup .pages-group .nav-link-infos {
	margin-left: 0px;
}

.default-display .PagesGroup .pages-group .nav-link .nav-link-page-title {
	padding-left: 3px;
}

.default-display .PagesGroup .pages-group .nav-link-page-icon {
	display: none;
}

.default-display .PageButton.active .nav-link-page-icon {
    filter: invert(1);
}

.default-display .nav-link {
	flex-direction: row;
    width: 100%;
    justify-content: space-between;
}


.default-display .nav-link-infos {
	gap: 0.5rem;
	margin-left: 10px;
	margin-right: 0.25rem;
}

.default-display .PageButton .nav-link .nav-link-page-title {
	margin-top: 0px;
    padding-left: 10px;
}

.default-display .PageButton .nav-link-page-icon {
	width: 20px;
	height: 20px;
	filter: unset;
}

body:not(.theme-dark) .default-display .PageButton.active .nav-link-page-icon {
	filter: unset;
}

.default-display .notif-count {
	position: relative;
	font-family: "Poppins-Bold";
	font-size: 0.5rem;
	line-height: 0.875rem;
	text-align: center;
	padding-top: 2px;
	min-width: 16px;
}

.default-display .notif-count.over99:after {
    content: "+";
    position: absolute;
    color: red;
    top: -5px;
    right: -5px;
}

.default-display .PageLink .notif-count {
    margin-right: 14px;
}
