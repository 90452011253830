.PlateformChanger {
	display: flex;
	background-color: var(--background-color);
	padding: 4px;
	gap: 0.25rem;
	border-radius: 6px;
	
}

.theme-dark .PlateformChanger {
	background-color: var(--background-color-secondary);
}

.rounded-buttons .PlateformChanger {
	border-radius: 100px;
}
