.images-cont {
	background-color: lightgray;
	background-color: var(--background-color-darken);
	width: 100%;
	border-radius: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 8px 15px 4px 15px;
	position: relative;
}

.images-row .rssImages {
	width: auto;
}

.upload-post-image-btn {
	display: flex;
	justify-content: center;
	flex-direction: column;
	border: 1px dashed #9b9b9b;
	align-items: center;
	font-size: 3.75rem;
	font-family: "Poppins-Light";
	color: #9b9b9b;
	cursor: pointer;
	width: 100px;
	min-width: 100px;
	height: 100px;
	border-radius: 6px;
	margin-bottom: 6px;
	text-align: center;
	padding: 5px;
	position: relative;
}

.add-image-input-cont {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	z-index: 2;
}

.add-image-input-cont span {
	font-size: 0.8rem;
	display: block;
	margin-top: -1.2rem;
}

.error-msg {
	color: #ff0a18;
	font-size: 0.8rem;
	text-align: right;
}

.add-image-input {
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 2;
	position: relative;
}

.import-msg {
	font-size: 0.75rem;
	line-height: 0.6875rem;
	word-break: break-word;
	z-index: 0;
}

.import-plus-btn {
	font-size: 4.375rem;
	line-height: 2.625rem;
	font-family: "Poppins-Thin";
	z-index: 0;
}

.images-row {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	border-radius: 6px;
	padding: 0px 10px 0px 10px;
	margin-right: 10px;
	display: flex;
}

.images-row > * {
	display: flex;
	width: 100%;
	min-height: 100px;
	position: relative;
}

.images-row .image-preview:first-child {
	margin-left: 0;
}
