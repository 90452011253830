.pwa_overlay {
	background: #fff;
}

.pwaModal {
	display: flex;
	padding: 0 1rem;
	justify-content: flex-end;
}

.pwaModal svg {
	cursor: pointer;
	color: var(--color-primary);
}

.pwaModalContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	text-align: center;
}

.pwaModalContainer h4 {
	font-size: 1.5rem;
	margin: 1rem 0;
}

.pwaModalContainer .gray {
	background: #ddd;
	margin-bottom: 0.5rem;
}

.pwaModalContainer .gray svg {
	margin-left: 1rem;
}
