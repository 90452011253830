.LoadCont {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.LoadCont.inline {
	flex-direction: row;
	gap: 0.25rem
}

.LoadCont .circle {
	border-top: 3px solid transparent;
	border-left: 3px solid var(--color-primary);
	border-right: 3px solid var(--color-primary);
	border-bottom: 3px solid transparent;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	position: relative;
	animation: circle 3.8s infinite linear;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.LoadCont.primary-bg .circle
{
	border-left: 3px solid var(--color-primary-font-color);
	border-right: 3px solid var(--color-primary-font-color);
}

.circle .circle {
	width: 100%;
	height: 100%;
	animation-delay: 0.1s;
	animation-duration: 4s;
} 

@keyframes circle {
	0% { transform: rotate(0deg);}
	100% { transform: rotate(360deg);}
}
