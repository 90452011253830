.post-preview .FormTile {
    background-color: var(--background-color);
    border-radius: 6px;
    padding: 5px 10px;
    border-left: 6px solid var(--color-primary);
	font-size: 0.75rem;
}

.edit-drawer .post-preview .FormTile {
	background-color: var(--background-color-secondary);
}

.FormTile .form-text {
	line-height:  0.75rem;
}
