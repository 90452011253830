#main-cont {
	display: flex;
	position: relative;
	overflow: hidden;
	background-color: white;
	background-color: var(--background-color);
	z-index: 0;
}

@media screen and (max-width: 700px) {
	.scroll-mobilenav #main-cont {
		overflow: auto;
	}
}
