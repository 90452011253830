
.MenuToggler
{
    color: white;
    width: 30px;
    cursor: pointer;
    display: none;
}

.MenuToggler hr {
    background-color: white;
    background-color: var(--color-primary);
    width: 100%;
    margin: 8px 0px;
    opacity: 1;
    height: 2px;
	transition-duration: 0.5s;
}

.MenuToggler::before, .MenuToggler::after {
    content: "";
    border: 1px solid white;
    border: 1px solid var(--color-primary);
	background-color: var(--color-primary);
    width: 100%;
    display: block;
	transition-duration: 0.5s;
}

.MenuToggler.open hr {
	opacity: 0;
}
.MenuToggler.open::before {
	transform: rotateZ(45deg) translate(7px, 9px);
}

.MenuToggler.open::after {
	transform: rotateZ(-45deg) translate(4px, -7px);
	
}