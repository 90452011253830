.color-picker {
	border: 4px solid var(--background-color-secondary);
	background-color: var(--background-color-secondary);
	border-radius: 10px;
}

.e-colorpicker-popup {
	border-radius: 10px !important;
	overflow: hidden;
}

.e-container .e-slider-preview {
	background-color: var(--background-color-secondary);
}

.e-container .e-slider-preview .e-preview-container .e-preview.e-current {
	border-bottom: 1px solid var(--background-color);
}

.e-container .e-slider-preview .e-preview-container {
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid var(--background-color);
}

.e-container .e-switch-ctrl-btn {
	background-color: var(--background-color-secondary);
}

.e-colorpicker-wrapper {
	width: 100% !important;
}

.e-colorpicker-wrapper * {
	box-sizing: border-box;
	width: 100%;
	border: none;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
	font-family: initial;
	line-height: 0.875rem;
	padding: 0 4px;
	width: 100%;
}

.e-split-btn-wrapper .e-btn {
	box-shadow: none;
	width: auto;
	background-color: var(--background-color-secondary);
}

.e-colorpicker-wrapper
	.e-split-btn-wrapper
	.e-split-colorpicker.e-split-btn
	.e-selected-color {
	height: 40px !important;
	width: 100% !important;
	display: block;
}

.theme-dark .e-dropdown-btn .e-caret,
.theme-dark .e-dropdown-btn.e-btn .e-caret {
	font-size: 0.5rem;
	filter: invert(1);
}

.e-btn {
	border: none;
	font-size: 0.875rem;
	height: auto;
}

.e-container .e-switch-ctrl-btn .e-ctrl-btn .e-btn {
	padding: 12px;
}

.e-container.e-color-picker .e-mode-switch-btn {
	background: transparent url(./assets/color-wheel.png) no-repeat 100% 100% !important;
	background-size: contain !important;
}

.e-container .e-palette .e-tile {
	height: 20px;
	width: 20px;
	border-radius: 10px;
	margin: 2px;
}

.e-ctrl-btn > *:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.e-ctrl-btn > *:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.e-container .e-switch-ctrl-btn .e-ctrl-btn .e-btn {
	padding: 12px;
	background-color: var(--background-color-darken);
	color: var(--font-color);
}

.e-container .e-switch-ctrl-btn .e-ctrl-btn .e-btn:hover {
	filter: brightness(0.9);
}

.light-bg.color-picker {
	border: 4px solid var(--background-color);
}
.light-bg.e-dropdown-popup,
.light-bg.e-dropdown-popup .e-container {
	background-color: var(--background-color-secondary);
	box-shadow: 0px 0px 10px 0px #0000002e;
}

.light-bg.e-dropdown-popup.color-picker {
	border: 4px solid var(--background-color);
}

.e-container .e-selected-value {
	background-color: var(--background-color-secondary);
	color: var(--font-color);
}

.e-selected-value * {
	color: var(--font-color) !important;
}
