.marge-align-bottom {
	margin-bottom: 4.2rem;
}

.form-checkbox {
	display: flex;
	align-items: center;
	gap: 0.3rem;
}

.text-checkbox {
	margin-top: -0.15rem;
}

.hidden {
	display: none;
}

.goBackBtn {
	width: auto !important;
}

.tac {
	text-align: center;
}

.rss-preview {
	margin-left: 1rem;
}

.rss-preview .post-preview {
	overflow-y: scroll;
	padding-bottom: 3.25rem;
}

.rss-preview .post-image {
	background-size: cover;
	height: 20rem;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	margin-bottom: 2rem;
}

.rss-preview .post-title {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.rss-link-article {
	position: absolute;
	bottom: 0;
	top: auto;
	font-size: 0.7rem;
	padding-bottom: 1rem;
	width: 96%;
	color: #999;
	text-align: right;
}

.rss-filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	gap: 1.5rem;
	margin-top: 2.5rem;
}

.cards-container__empty {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.rss-filters > div {
	display: inline-flex;
	font-size: 0.7rem;
	border-radius: 60px;
	text-align: center;
	margin: 0;
	padding: 0rem 0.4rem;
	transition-duration: 0.3s;
	word-break: initial;
	text-transform: uppercase;
	font-family: "Poppins-SemiBold";
	background-color: var(--background-color-secondary);
	color: var(--font-color);
	max-width: 200px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: keep-all;
	cursor: pointer;
}

.rss-filters > .active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}
