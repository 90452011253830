.ProgressBar {
	width: 100%;
	height: 5px;
	border-radius: 10px;
	background-color: rgba(var(--color-primary-rgb), 0.2);
	overflow: hidden;
}

.ProgressBar .progress-value {
	height: 100%;
	background-color: var(--color-primary);
	border-radius: 10px;
}
