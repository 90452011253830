.post-linked-docs-cont {
	display: flex;
	flex-direction: column;
    position: absolute;
    left: 0px;
	bottom: -100%;
    overflow: hidden;
    width: 100%;
	height: 100%;
    background-color: rgba(var(--background-color-secondary-rgb), 0.97);
	backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 15px;
	z-index: 3;
	transition-duration: 0.3s;
}

.post-preview-cont.extended .post-linked-docs-cont {
	position: fixed;
}

.post-linked-docs-inner-cont
{
	overflow: auto;
	height: 100%;
}

.post-linked-docs-cont.open {
	bottom: 0%;
	transition-duration: 0.6s;
}

.close-post-docs-btn
{
	font-size: 1.875rem;
    line-height: 0rem;
}

.post-preview .document-tile
{
	margin: 0;
	margin-bottom: 10px;
	background-color: var(--background-color);
	width: 100%;
}

/*
** -- Linked doc Viewer
*/

.post-preview-cont .pdf-viewer-cont{
	z-index: 3;
    background-color: var(--background-color-secondary);
    display: flex;
    height: 100%;
	padding: 15px;
}

.post-preview-cont.extended .pdf-viewer-cont
{
	position: fixed;
}
