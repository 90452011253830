
.targets-popper {
	z-index: 90;
	position: fixed;
	min-width: 200px;
	max-width: 400px;
	max-height: 300px;
	box-shadow: 0px 0px 5px 1px var(--shadow);
	background-color: var(--background-color);
	border-radius: 12px;
	padding: 12px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.targets-popper .popper {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.theme-dark .targets-popper .popper {
	background-color: var(--background-color);
}
