.post-docs-btn {
	padding: 1px 10px;
	border-radius: 70px;
	background-color: var(--color-primary);
	color: white;
	color: var(--color-primary-font-color);
}

.closeBtn {
	position: absolute;
	right: 1rem;
	top: 2.3rem;
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
}

.mobile .no-comment {
	font-size: 0.6rem;
	line-height: 0.9;
}
