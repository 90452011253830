.PostStats {
    position: absolute;
    background-color: var(--background-color-secondary);
    width: 100%;
    height: 100%;
    z-index: 3;
    padding: 20px;
    top: 0px;
    left: 0px;
	overflow: auto;
	font-size: 0.875rem;
}

.PostStats .post-tile.active {
    border: unset;
	background-color: var(--background-color);
}

.PostStats .post-tile.active .post-text-hidder {
    background: linear-gradient(0deg, var(--background-color) 20%, #0000);
}

.PostStats .body > div {
	padding: 0.5rem 0px;
}

.PostStats .body > div:not(:last-child) {
	border-bottom: 1px solid var(--background-color);
}

.PostStats hr {
	color: var(--background-color);
	opacity: 1;
}

.PostStats .targets-list {
	max-height: 300px;
	overflow: auto;
}

.PostStats .post-target-image-cell {
	width: 40px;
}

/*
** -- Reaction Table
*/

.PostStats .tabs-cont {
	border: 1px solid var(--background-color);
}

.PostStats .ReactionsTable .tabs-head {
	font-size: 12px!important;
	font-family: "Poppins-SemiBold";
}

.PostStats .ReactionsTable .tabs-head .tab-title {
	border-bottom: 2px solid var(--background-color);
}

.PostStats .ReactionsTable .tabs-head .tab-title.active {
	background-color: unset;
	color: var(--font-color)!important;
	border-bottom: 2px solid var(--color-primary);
}

.PostStats .ReactionsTable .tabs-head .reac-count {
	margin: 0;
}
