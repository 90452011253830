/*
** -- Switch
*/

.SwitchInput:not(.disabled) {
	cursor: pointer;
	width: fit-content;
}

.switch {
	position: relative;
	box-sizing: border-box;
	height: 35px;
	cursor: pointer;
	width: 64px;
	margin-left: 0px;
	-moz-appearance: initial;
	-webkit-appearance: initial;
	background-color: transparent;
	z-index: 0;
}

.switch::before {
	background-color: #b6b6b6;
	width: 100%;
	display: block;
	content: " ";
	height: 100%;
	border-radius: 20px;
	z-index: 1;
	position: relative;
	transition-duration: 0.5s;
}

.switch::after {
	background-color: #ffffff7d;
	content: " ";
	display: block;
	width: 16px;
	height: 16px;
	z-index: 2;
	position: absolute;
	border-radius: 50%;
	top: 9.5px;
	left: 13px;
	transition-duration: 0.5s;
}

.switch:checked::before {
	background-color: #2e8aba;
	background-color: var(--color-primary);
}

.switch:checked::after {
	left: calc(100% - 29px);
}
