.RecorderToolbar {
	position: fixed;
	top: 0;
	left: 50%;
	translate: -50% 0;
	z-index: 30;
	padding: 5px 10px;
	background-color: var(--background-color-secondary);
	box-shadow: 0px 0px 4px 2px var(--shadow);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.path-item.error input {
	border: 1px solid red;
}

.tour-editor-highlight {
	border: 2px solid red!important;
}

.tour-editor-highlight button,
.tour-editor-highlight a  {
	pointer-events: visible;
}

.no-highlight .tour-editor-highlight {
	border: unset!important;
}

.TourStepPopper {
	z-index: 30;
	background-color: var(--background-color-secondary);
	box-shadow: 0px 0px 4px 2px var(--shadow);
	padding: 0.5rem;
	border-radius: 12px;
	overflow: auto;
	max-height: 80vh;
	width: 50vw;
	min-width: 300px;
	max-width: 100vw;
}

.TourPreviewModal .TourPreviewItem .form-input,
.TourPreviewModal .TourPreviewItem input:not([type='checkbox']) {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	width: 100%;
}

.TourEndStep .TourPreviewItem {
	background-color: white;
}

.TourStepPopper .form-input
{
	border-radius: 6px;
	width: 100%;
}

.TourPreviewItem .item-drag-handle {
	cursor: ns-resize;
}
