.errors-cont {
	font-weight: 400;
	background-color: #c5030b;
	color: white;
	padding: 8px;
	border-radius: 6px;
	position: relative;
}

.errors-cont.closable {
	padding-top: 20px;
}

.errors-cont:empty
{
	display: none;
}

.close-errors-btn {
	position: absolute;
	font-size: 0.75rem;
	left: 8px;
	top: 2px;
}

.errors-cont.warn {
	background-color: #ff9500;
}
