.profile-cont {
	overflow: auto;
	overflow-x: hidden;
}

.profile-cont > *
{
	display: inline-block;
	padding: 20px;
	border-radius: 10px;
	background-color: var(--background-color-secondary);
    width: 100%;
	height: fit-content;
    /* margin: 10px; */
	max-width: 500px;
}

.profile-cont .user-image {
	width: 150px;
	position: relative;
}
