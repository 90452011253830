.pdf-viewer-cont
{
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--background-color);
	transition-duration: 0.5s;
	z-index: 4;
	padding: 3%;
}

.pdf-viewer-cont.open
{
	top: 0%;
}

.viewer-top-bar {
	display: flex;
	flex-direction: column;;
}

.close-pdf-viewer
{
	font-size: 1.875rem;
	line-height:  1.875rem;
	cursor: pointer;
	display: block;
	width: fit-content;
}

.doc-cats:empty {
	display: none;
}

.doc-name {
    font-size: 1.875rem;
    line-height: 2rem;
	max-width: 100%;
	overflow-wrap: anywhere;
}

.pdf-viewer
{
	width: 100%;
	height: 100%;
	border-radius: 12px;
	border: 2px solid var(--background-color-darken);
}

.pdf-viewer-cont .favorite-icon {
	font-size: 1.25rem;
}

/*
** -- sharer
*/

.document-sharer-popper .link-field {
	border: 2px solid var(--background-color);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 0.5rem;
}

.document-sharer-popper .clip-copy-btn {
	border: 2px solid var(--background-color-lighten);
	border-radius: 6px!important;
}

.document-sharer-popper .preview-tile {
	background-color: var(--background-color);
	margin: 0;
	pointer-events: none;
}
