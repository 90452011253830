
.FormGenerator {
	max-width: 100%;
	color: var(--font-color)!important;
	height: 100%;
	display: flex;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.FormGenerator .FormSections {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
	width: calc(100% - 50px);
}

.FormGenerator input[type='text'],
.FormGenerator textarea {
	border: none;
	outline: none;
	border-bottom: 1px solid white;
	border-bottom: 1px solid var(--background-color-secondary);
	background-color: var(--background-color);
	padding: 5px;
}

.FormGenerator input[type='text']:disabled,
.FormGenerator textarea:disabled {
	background-color: #d9d9d9;
	background-color: var(--background-color-darken);
	border-bottom: none;
}

.FormGenerator input[type='text']:not(:disabled),
.FormGenerator textarea:not(:disabled) {
	background-color: rgb(246 246 246);
	background-color: var(--background-color);
	transition-duration: 0.3s;
}

.FormGenerator input[type='text']:hover:not(:disabled),
.FormGenerator textarea:hover:not(:disabled) {
	border-bottom: 1px solid rgb(194, 194, 194);
	transition-duration: 0.3s;
}

.FormGenerator input[type='text']:focus:not(:disabled),
.FormGenerator textarea:focus:not(:disabled) {
	border-bottom: 1px solid blue;
	border-bottom: 1px solid var(--color-primary);
	transition-duration: 0.3s;
}

.FormGenerator textarea {
	min-height: fit-content;
	max-height: 200px;
}

.FormGenerator .form-select {
	background-color: var(--background-color-secondary);
	border: none;
}

.FormGenerator .field-error {
	border: 1px solid red!important;
	border-radius: 3px;
}


@media  (max-width: 600px) {

	.FormGenerator {
		max-width: 100%;
	}

	.FormGenerator .FormSections {
		padding-bottom: 40px;
		width: 100%;
	}
}
