.markerio-io-btn {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
	font-size: 0.75rem;
	transform: rotate(-90deg);
	transform-origin: right;
	position: fixed;
	right: 7px;
	top: calc(50% - 100px);
	padding: 3px 5px;
	padding-bottom: 10px;
	border-radius: 6px;
	cursor: pointer;
	z-index: 999999;
}
