.TableToolBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--background-color-secondary);
	padding: 0.25rem;
	border-radius: 6px;
	/* z-index: 3; */
}

.TableToolBar .checkbox:not(:checked) {
	border: 1px solid var(--color-primary);
}

.TableToolBar .sep {
	width: 1px;
	height: auto;
	background-color: var(--background-color);
	display: flex;
}

.TableToolBar .TagLabel {
	font-size: 0.75rem;
	padding: 0.3rem;
	border-radius: 10px;
}

@media screen and (max-width: 600px) {
	.TableToolBar .btn-title {
		display: none;
	}
}

.TableToolBar .container_btn {
	overflow: hidden;
}
.TableToolBar .container_btn > * {
	float: left;
	width: auto;
	padding: 5px 1rem !important;
}

/* Button Popper */

.TableToolBar .ToolbarButton {
	display: flex;
	overflow: visible;
	align-items: center;
}

.toolbar-popper-cont .popper-load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(var(--background-color-rgb), 0.7);
	z-index: 2;
}
