.LoginPage {
	width: 100%;
	margin: auto;
	opacity: 1 !important;
	z-index: 1;
	position: relative;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
	flex-direction: column;
	padding-top: 8vh;
	box-sizing: border-box;
	background-image: url(../../assets/backgrounds/fond-admin.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transition-duration: 0.3s;
	color: white;
}

.LoginPage.CGU .login-wrapper {
	margin-bottom: 30vh;
	background-color: #000000a6;
}


.LoginPage .login-wrapper {
	position: relative;
	background-color: #00000078;
	padding: 20px;
	border-radius: 10px;
	transition-delay: 0.2s;
	transition-duration: 0.5s;
	backdrop-filter: blur(14px);
	margin: 0 5%;
	min-width: 400px;
	max-width: 1000px;
	color: white;
	width: auto;
}

.LoginPage .logo-cont {
	width: 80%;
	height: 20vh;
    max-height: 280px;
	max-width: 400px;
	display: block;
	background-image: url(assets/logo-login.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto;
	margin-bottom: 5%;
	transition-duration: 0.3s;
	opacity: 1;
}

.LoginPage .Button.unstyled {
	color: white;
}

.LoginPage .join-cont {
	background-color: #000000a6;
	padding: 1rem;
	border-radius: 10px;
	margin: auto;
	backdrop-filter: blur(14px);
}
