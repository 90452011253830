
.StatsBody .stat-module-title {
	font-size: 0.875rem;
	line-height: 1rem;
	font-family: "Poppins-SemiBold";
	/* text-transform: uppercase; */
}

.StatsBody .sub-info {
	color: grey;
	font-size: 0.875rem;
}

.StatsBody .number-chart-value-cont {
	font-family: "Poppins-Thin"!important;
	font-size: 3.75rem!important;
	line-height: 3.75rem!important;
	letter-spacing: -1px;
}

.StatsBody .CountButton.active .number-chart-value-cont {
	color: white!important;
}
