/*
** -- Passwords cont 
*/

.password-input-cont {
	background-color: var(--input-color);
	/* padding-right: 8px; */
	border-radius: 10px;
}

/*
**
*/

input:invalid {
	border: red solid 1px;
}

/*
** -- CheckBox inspired from - https://www.creativejuiz.fr/blog/tutoriels/personnaliser-aspect-boutons-radio-checkbox-css
*/

/* Cachons la case à cocher */
form p {
	position: relative; /* permet de positionner la checkbox cachée */
}

.checkbox {
	appearance: none;
	-moz-appearance: initial;
	border-radius: 4px;
	overflow: hidden;
	min-height: unset !important;
	max-height: unset !important;
	padding: 0 !important;
}

.checkbox:not(:checked),
.checkbox:checked {
	position: absolute;
	left: 0;
}

/* on prépare le label */
.checkbox:not(:checked),
.checkbox:checked {
	position: relative;
	cursor: pointer;
	width: 18px !important;
	height: 18px;
	border: none;
}

/* Aspect de la case */
.checkbox:not(:checked)::before,
.checkbox:checked::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	transition: all 0.275s;
	border: none;
	border-radius: 4px;
	box-sizing: border-box;
}

/* Aspect de la coche */
.checkbox:not(:checked)::after,
.checkbox:checked::after {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	transition: all 0.2s;
	background-color: #0b608d;
	background-color: var(--color-primary);
	border-radius: 10px;
	width: 18px;
	height: 18px;
	opacity: 1;
}

/* Aspect non cochée */
.checkbox:not(:checked) {
}

.checkbox:not(:checked)::after {
	opacity: 0;
	transform: scale(0);
}

/* Aspect cochée */
.checkbox:checked::after {
	opacity: 1;
	transform: scale(1);
}

/* Disabled checkbox */
.checkbox:disabled:not(:checked)::before,
.checkbox:disabled:checked::before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #e9e9e9;
}

/* Disabled checked */
.checkbox:disabled:checked::after {
	color: #777;
}

.checkbox:disabled {
	color: #aaa;
	cursor: not-allowed;
}

/* Accessibility focus */
.checkbox:checked:focus::before,
.checkbox:not(:checked):focus::before {
}
