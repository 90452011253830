.SitesPage {
	display: flex;
	flex-direction: column;
}

.SitesList {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	display: table-row;
	margin-left: -12.5px;
	width: calc(100% + 12.5px) !important;
	flex-wrap: wrap;
}

/*
** MAP Cont
*/

.MapCont {
	/* position: absolute; */
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
}

.MapCont .map-cont {
	height: 100%;
}

@media (max-width: 700px) {
	.SitesList .infinite-scroll-component {
		width: 103%;
		padding-right: 2rem;
		padding-bottom: 4rem;
	}
	.SitesList .infinite-scroll-component__outerdiv {
		overflow: hidden;
	}
	.SitesList {
		padding: 0 5% 9rem 5%;
		width: 100%;
		margin-left: 0;
		position: relative;
		z-index: 1;
		/* overflow: hidden; */
	}
	.SitesList .SiteTile.tile {
		width: 100% !important;
		margin: 0 0 1rem 0;
	}
}
