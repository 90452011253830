.UsersManager {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: var(--background-color-secondary);
	z-index: 3;
	padding: 3%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.btn.ManagerItem {
	width: auto;
	flex: 1 1 200px;
	padding: 10px 10px;
	background-color: var(--background-color);
	height: 50px;
}

.UsersManager .Filters {
	padding: 0.5rem;
	background-color: var(--background-color);
	border-radius: 6px;
	font-size: 0.875rem;
}

.UsersManager .UsersExporter {
	overflow: unset!important;
	height: unset!important;
}

.UsersManager .UsersExporter .UserPropsSelector {
	min-height: 300px;
}
