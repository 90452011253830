.page-load-cont {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 10;
	border-radius: 25px;
	overflow: hidden;
	position: relative;
}

.page-load-bg {
	position: absolute;
	top: -100%;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	opacity: 0;
}

.page-load-bg.hasImage
{
	top: 0;
	opacity: 1;
	transition-delay: 0.3s;
	transition-duration: 1s;
}

.inner-load
{
	background-color: rgba(var(--background-color-rgb), 0.7);
	backdrop-filter: blur(10px);
	padding: 30px;
	border-radius: 25px;
	color: var(--font-color);
}

.spinner-cont 
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
