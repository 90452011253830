.dropdown--is-disabled {
	filter: brightness(0.95) opacity(0.7);
	cursor: not-allowed!important;
	pointer-events: initial;
}

.dropdown__control--is-disabled {
	cursor: not-allowed!important;
}

.dropdown__control--is-disabled .dropdown__indicator {
	pointer-events: none;
}
