.document-tile .del-doc-icon,
.document-tile .favorite-icon:not(.saved) {
	opacity: 0;
	transition-duration: 0.4s;
}

.document-tile:hover .del-doc-icon,
.document-tile:hover .favorite-icon {
	opacity: 1;
	transition-duration: 0.2s;
}

.document-tile .doc-title {
	line-height:  1.125rem;
}
