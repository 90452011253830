.edit-drawer {
	position: absolute;
	top: 100vh;
	padding: 3vh 6%;
	transition-duration: 0.5s;
	background-color: var(--background-color);
	z-index: 10;
	width: 100%;
	left: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	--not-active-color: #888888;
	overflow-x: hidden;
	overflow-y: scroll;
}

.edit-drawer-inner-cont {
	width: 100%;
	max-width: 1000px;
}

.btn-close-edit-drawer {
	font-size: 1.875rem;
	cursor: pointer;
}

.drawer-title {
	font-size: 2.8125rem;
	line-height: 3rem;
	font-family: "Poppins";
	font-weight: 400;
	transition-duration: 0.5s;
	margin-top: 0px;
	margin-bottom: 20px;
	margin-left: 22px;
	color: var(--color-primary);
}

.wizard-cont > *:not(.steps-nav),
.wizard-cont > *:not(.steps-nav) > * {
	height: 100%;
}

.wizard-cont > *:not(.step-nav).fullscreen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
}

.step-cont {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
}

/*
** -- InnerStepCont
*/

.step-cont-head {
	font-size: 1.25rem;
	font-family: "Poppins-ULight";
	/* border-bottom: 1px solid grey; */
	/* margin: 1.375rem 1.375rem 0.25rem 1.375rem; */
	cursor: pointer;
}

.inner-step-cont {
	background-color: var(--background-color-secondary);
	padding: 1.375rem;
	border-radius: 16px;
}

@media screen and (min-width: 768px) {
	.inner-step-cont {
		flex-direction: column !important;
	}
}

.inner-step-cont:not(:last-child) {
	margin-bottom: 1rem;
}

.InnerStepCont {
	margin: 1.375rem 1.375rem 0.25rem 1.375rem;
}

.InnerStepCont:first-child {
	margin-top: 0;
}

.InnerStepCont .step-cont-arrow {
	transition-duration: 0.3s;
	font-size: 1rem;
}

.InnerStepCont.open .step-cont-arrow {
	transform: rotate(90deg);
}

.step-cont .inner-step-cont .dropdown__control {
	max-width: 100%;
	background-color: var(--background-color);
	border: 1px solid var(--background-color);
}

/*
** -- Steps Links
*/

.inner-step-cont .tabs-cont {
	border: 1px solid var(--background-color-darken);
}

@media screen and (max-width: 1000px) {
	.drawer-title {
		margin-top: 0px;
		margin-bottom: 10px;
		line-height: 2.1875rem;
		font-size: 1.5rem;
		font-weight: 500;
	}

	.edit-drawer {
		padding: 2%;
	}
}
