.StatsTopBar {
	background-color: var(--background-color-secondary);
	padding: 5px;
	border-radius: 12px;
	width: 100%;
	font-size: 0.875rem;
}

.StatsTopBar .date-picker {
	font-size: 0.875rem;
}
