.TypeGroup

.TypeTile {
	border-radius: 6px;
	background-color: var(--background-color);
	text-align: center;
	cursor: pointer;
}

.TypeTile.active,
.TypeTile:hover {
	outline: 3px solid var(--color-primary);
}

.TypeTile .type-image {
	width: 100px;
	height: 100px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 80%;
}
