.AccountsList {
	display: flex;
    justify-content: center;
    gap: 1.5rem;
	flex-wrap: wrap;
}

.AccountsList.hasActive {
	gap: 0;
}

.AccountsList.hasActive .account:not(.active) {
	pointer-events: none;
}

@media (max-width: 770px) {

	.AccountsList {
		gap: 0rem;
		flex-direction: column;
	}
}
