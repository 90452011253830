.sending-cont {
	display: flex;
	flex-direction: column;
}

.sending-step-cont {
	height: fit-content;
	display: flex;
	align-items: center;
}

.sending-step-cont:not(:last-child) {
	margin-bottom: 10px;
}

.state-icon {
	background-color: grey;
	border-radius: 100px;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1.5rem;
}

.state-icon.loading {
	
}

.state-icon.done {
	background-color: green;
}

.state-icon.error {
	background-color: red;
}

.SendingCont .error-cont {
	background-color: red;
	color: white;
	padding: 10px;
	border-radius: 6px;
	max-height: 20vh;
	overflow: auto;
}

.SendingCont:not(:last-child) {
	margin-bottom: 1rem;
}
