.AnimationPreview {
	height: 100%;
	overflow: scroll;
}

.page-cont.agenda-page-cont .preview-drawer {
	max-width: 500px;
	display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.page-cont.agenda-page-cont .preview-drawer .post-preview-cont {
	width: 100%;
	height: auto;
	aspect-ratio: unset;
}
.page-cont.agenda-page-cont .post-images-preview img {
    aspect-ratio: 14/11;
}

.AnimationPreview .post-preview {
	background-color: var(--background-color-secondary);
	margin: 0;
	border-radius: var(--radius-2);
	border-top-left-radius: var(--radius-4);
	border-top-right-radius: var(--radius-4);
}

.FallbackAnimationPreview {
	display: flex;
    flex-direction: column;
	background-color: var(--background-color-secondary);
	width: 100%;
    background-color: #fff;
    border-radius: var(--radius-2);
	padding: 25px;
    color: black;
    color: var(--font-color);
	overflow: hidden;
	position: relative;
}

.edit-drawer .AnimationPreview .post-preview
{
	background-color: var(--background-color);
	border-radius: var(--radius-2);
}

.FallbackAnimationPreview .load-error {
	color: grey;
	line-height:  0.875rem;
	font-size: 0.75rem;
}

@media screen and (max-width: 700px) {
	
	.page-cont.agenda-page-cont .preview-drawer {
		max-width: unset;
		width: 100%;
	}
}
