.chart-legend
{
    margin-top: 20px;
	width: 100%;
}

.value-legend {
    text-align: left;
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.value-legend:hover {
    background-color: var(--background-color);
}

.value-color
{
	height: 8px;
    width: 8px;
    border-radius: 30px;
}

.value-title
{
    font-size: 0.9375rem;
}

.chart-value
{
}
