/*
** -- edit poppup
*/

.edit-cont {
	position: absolute;
	top: 0px;
	left: 0px;
	width: calc(100% + 20px);
	height: calc(100% + 20px);
	/* background-color: red; */
	/* backdrop-filter: blur(5px); */
	background-color: var(--background-color-secondary);
	padding: 10px;
	z-index: 3;
	display: flex;
	flex-direction: column;
}

.edit-cont .edit-title {
	font-family: "Poppins-ULight";
	font-size: 1.875rem;
}

.edit-cont .picker-cont {
	width: 100px;
}

.edit-cont .color-picker {
	border: 4px solid var(--background-color);
}

.profession-color-picker.e-dropdown-popup,
.profession-color-picker.e-dropdown-popup .e-container {
	background-color: var(--background-color-secondary);
	box-shadow: 0px 0px 10px 0px #0000002e;
}

.profession-color-picker.e-dropdown-popup.color-picker {
	border: 4px solid var(--background-color);
}
