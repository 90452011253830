
.TableBody tr td:first-child
{
    font-family: "Poppins-Bold";
    font-size: 0.75rem;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.TableBody td:not(:first-child)
{
    font-family: "Poppins-Light";
    font-size: 0.75rem;
	padding-left: 0.8em;
}

.TableBody:not(.no-hover) tr
{
    transition-duration: 0.3s;
	background-color: var(--background-color-secondary);
	cursor: pointer;
}

.dark-bg .TableBody tr,
.light-bg .TableBody tr {
	background-color: var(--background-color);
}

.dark-bg .checkbox:not(:checked)::before, .checkbox:checked::before {
	background: var(--background-color);
}

.TableBody tr.active,
.TableBody tr.active td
{
    transition-duration: 0.3s;
    background-color: #2E8ABA !important;
    background-color: var(--color-primary) !important;
	color: var(--color-primary-font-color)
}

.TableBody:not(.no-hover) tr:hover
{
    background-color: rgb(56, 164, 223);
	background-color: rgba(var(--color-primary-rgb), 0.5);
    transition-duration: 0.1s;
	color: white;
}

.TableBody:not(.no-hover) tr:hover td input
{
    opacity: 1;
    transition-duration: 0.4s;
}

.TableBody tr td:last-child
{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.TableBody tr td > * {
	vertical-align: middle;
}

.TableBody tr td label {
	width: 100%;
}

.TableBody .no-items {
	pointer-events: none;
}

.TableBody .no-items-txt {
	font-size: 0.75rem;
	color: grey;
}


/*
** Checkbox
*/

.TableBody td input{
    vertical-align: text-top;
}

.TableBody td:first-child input {
    display: inline-block;
    vertical-align: middle;
}

.TableBody td:first-child input:checked {
    opacity: 1;
}

.TableBody tr.active input.checkbox:checked {
	border: 1px solid var(--background-color-secondary);
}
