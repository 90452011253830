.docMobile {
	background: #e5e5ea;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	margin: 0 1rem;
	border-radius: 16px;
}

.mobile .wrapperEditButtons {
	margin-right: 0.5rem;
	position: relative;
}

.mobile .wrapperEditButtons > .Button {
	border-radius: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding: 0.2rem;
}

.mobile .wrapperEditButtons > .Button:focus {
	outline: none;
	box-shadow: none;
}

.mobile .wrapperEditButtons > div {
	position: absolute;
	bottom: 3.9rem;
	background: rgba(255, 255, 255, 0.5);
	padding: 0.5rem 0;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.mobile .wrapperEditButtons .btn-edit-com {
	display: block;
	background: none;
	color: var(--text-color);
	padding: 0;
	margin-top: -0.7rem;
}

.mobile .wrapperEditButtons .btn-edit-com .icons {
	display: block;
	font-size: 3rem;
	color: var(--color-primary);
}

.mobile .wrapperEditButtons .btn-edit-com .icons + span {
	display: block;
	font-size: 0.55rem;
	color: var(--text-color);
	margin-top: -0.2rem;
}

.mobile .wrapperEditButtons .EditButtons {
	flex-direction: column-reverse;
	padding-bottom: 0.5rem;
}

.mobile .wrapperEditButtons .EditButtons .Button {
	background: none;
	color: var(--text-color);
	display: block;
	padding: 0;
	font-size: 0.55rem;
	flex: auto;
	line-height: 0.8;
}

.mobile .wrapperEditButtons .EditButtons .Button span {
	color: var(--color-primary);
	display: block;
	font-size: 3rem;
}

.mobile .container_param_images {
	align-self: flex-end;
}

.post-preview-cont.extended.mobile .post-preview .post-cont {
	padding-bottom: 7rem !important;
}
.mobile .wrapperEditButtons .EditButtons {
	margin-bottom: 0.5rem;
}

.docMobile .doc-btn-viewer:first-child {
	margin: 0;
}

.docMobile .doc-btn-viewer {
	display: flex;
	padding: 0.8rem 1rem 0.8rem 2.5rem;
	align-items: center;
	position: relative;
}

.docMobile .doc-btn-viewer_text {
	font-size: 0.8rem;
	max-height: 2.8rem;
	overflow: hidden;
}

.docMobile .doc-btn-viewer svg {
	font-size: 2rem;
	margin-right: 0.5rem;
}

.docMobile .doc-btn-viewer:after {
	content: "";
	border-bottom: 1px solid #fff;
	width: 90%;
	height: 1px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.docMobile .doc-btn-viewer:last-child:after {
	display: none;
}

.docMobile + .EditButtons {
	background: #e5e5ea;
	margin-top: -0.6rem;
}
