.ReactionListCell .ReactionIcon img {
	max-height: 30px;
}

.reactions-page-cont {
	overflow: auto;
}

.reactions-page-cont > div {
	padding-bottom: 5vh;
}

.reaction-title-cell {
	width: fit-content;
	max-width: 200px!important;
}
