.ReactionsBank {
	margin: -0.25rem;
}

.ReactionBankItem {
    padding: 5px;
    flex: 1 1 calc(20% - 0.5rem);
    background-color: var(--background-color);
    border-radius: 6px;
    margin: 0.25rem;
	max-height: 100px;
	position: relative;
	cursor: pointer;
}

.ReactionBankItem.selected {
	background-color: rgba(var(--color-primary-rgb), 0.3);
}

.ReactionBankItem .ReactionIcon img {
	height: 50px;
	width: auto;	
}

.ReactionBankItem .selected-index {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--color-primary);
    color: var(--color-primary-font-color);
    line-height:  0.375rem;
    font-size: 1.25rem;
    padding: 10px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 2;
    font-family: 'Poppins-Bold';
}
