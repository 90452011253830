.user-image {
	display: block;
	width: 50px;
	height: fit-content;
	aspect-ratio: 1;
    border-radius: 1000px;
	background-position: center;
	background-size: cover;
	background-color: var(--background-color);
	background-repeat: no-repeat;
}

.user-image.user-comp-logo {
	background-color: transparent!important;
	background-size: 100%;
	border-radius: 0px;
}

.editable-user-image
{
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
}

.editable-user-image input 
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}

.editable-user-image .hover-cont,
.editable-user-image .drag-cont
{
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.6);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 1000px;
	font-size: 1.25rem;
	color: white;
	z-index: 2;
	position: absolute;
	left: 0;
	top: 0;
}

.editable-user-image:not(.drag-over):hover .hover-cont,
.editable-user-image.drag-over .drag-cont
{
	opacity: 1;
}

.OnlineUserImage .OnlineStatus {
	position: absolute;
	right: 0px;
	bottom: 0px;
	padding: 5px;
	border-radius: 50px;
	background-color: #00a900 ;
	border: 2px solid #FFFFFFAA;
}
