@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.font-thin {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.font-ulight {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.font-light {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.font-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.font-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.font-semibold {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.font-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.font-heavy {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: normal;
}

.font-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "icons";
	src: url(assets/fonts/Icons/fontello.woff2) format("woff2"),
		url(assets/fonts/Icons/fontello.woff) format("woff"),
		url(assets/fonts/Icons/fontello.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

.icons {
	font-family: "icons";
	line-height: 1;
}

.icons.f-10 {
	font-size: 1rem;
}

.icons.f-11 {
	font-size: 1.1rem;
}

.icons.f-12 {
	font-size: 1.2rem;
}

.icons.f-13 {
	font-size: 1.3rem;
}

.icons.f-14 {
	font-size: 1.4rem;
}

.icons.f-15 {
	font-size: 1.5rem;
}

.icons.f-16 {
	font-size: 1.6rem;
}

.icons.f-17 {
	font-size: 1.7rem;
}

.icons.f-18 {
	font-size: 1.8rem;
}

.icons.f-19 {
	font-size: 1.9rem;
}

.icons.f-20 {
	font-size: 2rem;
}

.icons.f-21 {
	font-size: 2.1rem;
}

.icons.f-22 {
	font-size: 2.2rem;
}

.icons.f-23 {
	font-size: 2.3rem;
}

.icons.f-24 {
	font-size: 2.4rem;
}

.icons.f-25 {
	font-size: 2.5rem;
}

.icons.f-26 {
	font-size: 2.6rem;
}

.icons.f-27 {
	font-size: 2.7rem;
}

.icons.f-28 {
	font-size: 2.8rem;
}

.icons.f-29 {
	font-size: 2.9rem;
}

.icons.f-30 {
	font-size: 3rem;
}

.icon-rss-icon:before {
	content: "\e800";
} /* '' */
.icon-write:before {
	content: "\e801";
} /* '' */
.icon-bell:before {
	content: "\e802";
} /* '' */
.icon-trash:before {
	content: "\e803";
} /* '' */
.icon-pen:before {
	content: "\e804";
} /* '' */
.icon-home:before {
	content: "\e805";
} /* '' */
.icon-network:before {
	content: "\e806";
} /* '' */
.icon-user-research:before {
	content: "\e807";
} /* '' */
.icon-edit:before {
	content: "\e808";
} /* '' */
.icon-doc:before {
	content: "\e809";
} /* '' */
.icon-switch-entreprise:before {
	content: "\e80a";
} /* '' */
.icon-data:before {
	content: "\e80b";
} /* '' */
.icon-people-size:before {
	content: "\e80c";
} /* '' */
.icon-people-question:before {
	content: "\e80d";
} /* '' */
.icon-user-delete:before {
	content: "\e80e";
} /* '' */
.icon-user-add:before {
	content: "\e80f";
} /* '' */
.icon-search:before {
	content: "\e810";
} /* '' */
.icon-send:before {
	content: "\e811";
} /* '' */
.icon-puzzle:before {
	content: "\e812";
} /* '' */
.icon-calendar:before {
	content: "\e813";
} /* '' */
.icon-edit-calendar:before {
	content: "\e814";
} /* '' */
.icon-like:before {
	content: "\e815";
} /* '' */
.icon-star:before {
	content: "\e816";
} /* '' */
.icon-exchange:before {
	content: "\e818";
} /* '' */
.icon-home-like:before {
	content: "\e819";
} /* '' */
.icon-home-search:before {
	content: "\e81a";
} /* '' */
.icon-thumb-up:before {
	content: "\e81b";
} /* '' */
.icon-thumb-down:before {
	content: "\e81c";
} /* '' */
.icon-plus:before {
	content: "\e81d";
} /* '' */
.icon-group:before {
	content: "\e81e";
} /* '' */
.icon-settings:before {
	content: "\e81f";
} /* '' */
.icon-info:before {
	content: "\e820";
} /* '' */
.icon-doc-user:before {
	content: "\e821";
} /* '' */
.icon-bulle-like:before {
	content: "\e822";
} /* '' */
.icon-bulle-edit:before {
	content: "\e823";
} /* '' */
.icon-close:before {
	content: "\e824";
} /* '' */
.icon-agenda:before {
	content: "\e825";
} /* '' */
.icon-look:before {
	content: "\e827";
} /* '' */
.icon-draft:before {
	content: "\e828";
} /* '' */
.icon-prev:before {
	content: "\e82a";
} /* '' */
.icon-next:before {
	content: "\e82b";
} /* '' */

/*
** -- SF-Text
*/

@font-face {
	font-family: "Poppins-Thin";
	src: url(assets/fonts/Poppins/Poppins-Thin.ttf);
}

@font-face {
	font-family: "Poppins-ULight";
	src: url(assets/fonts/Poppins/Poppins-ExtraLight.ttf);
}

@font-face {
	font-family: "Poppins-Light";
	src: url(assets/fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
	font-family: "Poppins-Regular";
	src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url(assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
	font-family: "Poppins-Bold";
	src: url(assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
	font-family: "Poppins-Heavy";
	src: url(assets/fonts/Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
	font-family: "Poppins-Italic";
	src: url(assets/fonts/Poppins/Poppins-Italic.ttf);
}
