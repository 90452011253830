.steps-links {
	display: flex;
	margin-bottom: 1rem;
	z-index: 1;
	overflow-y: hidden;
	overflow-x: auto;
}

.step-link-cont {
	display: flex;
	align-items: flex-start;
	width: 100%;
	min-width: 80px;
	height: fit-content;
	color: var(--font-color);
}

.step-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
}

.step-index,
.step-title {
	cursor: pointer;
}

.is-done .step-index {
	pointer-events: none;
}

.step-title {
	margin-top: 2px;
	font-size: 0.875rem;
	text-align: center;
	line-height: 1rem;
	color: var(--not-active-color);
}

.active .step-title,
.checked .step-title {
	color: var(--font-color);
}

.step-index {
	background-color: var(--background-color);
	border: 2px solid var(--not-active-color);
	color: var(--not-active-color);
	border-radius: 50px;
	font-size: 0.875rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	z-index: 2;
	transition-duration: 0.3s;
}

.step-link.active .step-index {
	border: 2px solid var(--font-color);
	color: var(--font-color);
}

.active .step-index {
	border: 2px solid var(--color-primary);
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.checked .step-index {
	background-color: green;
	color: white;
}

.failed .step-index {
	background-color: red;
	border: 2px solid rgb(181, 0, 0);
	color: white;
}

.checked.failed .step-index {
	background-color: orange;
	border: 2px solid rgb(196, 128, 2);
	color: white;
}

.step-link-cont:not(:last-child) .step-index:after {
	content: "";
	width: calc(100% - 15px);
	left: calc(50% + 15px);
	border: 1px solid var(--not-active-color);
	position: absolute;
	z-index: 1;
	pointer-events: none;
	transition-duration: 0.3s;
}

.step-link-cont:not(:last-child).checked .step-index:after {
	border: 1px solid var(--font-color);
}

.step-link-cont.checked .step-index {
	border: 2px solid #0000004f;
}

@media screen and (max-width: 550px) {
	.step-title {
		font-size: 0.625rem;
		line-height: 0.75rem;
	}

	.steps-links {
		margin-bottom: 10px;
	}
}

.steps-links.mobile .step-index {
	width: 25px;
	height: 25px;
	font-size: 0.75rem;
}

.steps-links.mobile .step-link-cont:not(:last-child) .step-index:after {
	width: calc(100% - 10px);
	left: calc(50% + 10px);
}

.steps-links.mobile .step-link-cont {
	min-width: 70px;
}
