.FormsPage {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.FormEdit .Assignator {
	padding-bottom: 60px;
}

.FormsTable .form-desc-cell {
	max-width: 250px!important;
}
