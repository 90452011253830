.post-comments-drawer {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0px;
	bottom: -100%;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: rgba(var(--background-color-secondary-rgb), 0.97);
	backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 15px;
	z-index: 3;
	transition-duration: 0.3s;
}

.post-comments-drawer .commentMobile .write-comment-cont textarea {
	background: #fff;
	overflow: hidden !important;
	padding: 0.6rem 1rem;
	font-size: 0.9rem;
	/* position: absolute; */
	height: 100%;
	margin: 1rem 0;
	min-height: 7rem;
	max-height: none;
}
.post-comments-drawer .commentMobile .write-comment-cont textarea:focus {
	outline: none;
}

.wrapper-mobile-commentSender .container-commentSender {
	margin-top: 3rem;
}

.post-comments-drawer .commentMobile form {
	display: block !important;
}

.commentMobile .write-comment-cont .send-btn {
	display: block;
	margin: auto;
	background: none;
	color: var(--text-color);
	/* height: 4rem; */
	padding: 0.5rem 1rem;
	/* width: 4rem; */
	line-height: 0.6;
	font-size: 0.7rem;
	margin-bottom: 9rem;
}

.commentMobile .comments-cont {
	padding-bottom: 7rem;
}

.commentMobile .write-comment-cont .send-btn span {
	display: block;
	font-size: 2rem;
	color: var(--color-primary);
	margin-top: -0.5rem;
}
.commentMobile textarea::placeholder {
	color: #aeaeb2;
}

.post-comments-drawer.containerCommentMobile {
	background: #f2f2f4;
}

.mobile_comment-date {
	font-size: 0.6rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.containerCommentMobile .comment {
	background: #fff;
	padding: 1.5rem 2rem 1rem;
	border-radius: 14px;
	margin: 0 1rem 1.5rem;
	box-shadow: 10px 15px 10px rgba(0, 0, 0, 0.05);
}

.containerCommentMobile .backButtonMobile {
	background: none;
}

.containerCommentMobile .comment > span {
	font-size: 0.9rem;
	line-height: 0.9;
}

.containerCommentMobile .user-infos {
	position: relative;
	padding-left: 0.4rem;
	font-size: 0.5rem !important;
	display: flex;
	justify-content: center;
	flex-direction: column;
	min-height: 25px;
}

.containerCommentMobile .user-infos > div:nth-child(2) {
	text-transform: uppercase;
}

.containerCommentMobile .user-infos:before {
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	width: 1px;
	background: #000;
}

.post-preview-cont.extended .post-comments-drawer {
	position: fixed;
}

.post-comments-drawer.open {
	bottom: 0%;
	transition-duration: 0.6s;
}

.post-comments-wrapper {
	height: calc(100% - 40px);
}
.comments-cont {
	overflow: auto;
}

.post-comments-drawer .write-comment-cont textarea {
	min-height: 35px;
	height: 35px;
	max-height: 200px;
	overflow: auto;
}

.comment {
	background-color: var(--background-color);
	border-radius: 6px;
	margin-bottom: 10px;
	padding: 5px;
	font-size: 0.75rem;
}

div.comment-list-author-preview .user-image {
	width: 25px;
}
.write-comment-cont .send-btn {
	width: fit-content;
	height: 35px;
}
