.map-cont {
	height: 33vh;
	min-height: 200px;
	z-index: 0;
}

.SiteEdit div.dropdown__control {
	min-height: 25px;
	height: 34px;
}

.SiteEdit div.dropdown__value-container {
	max-height: 30vh;
	overflow: auto;
	margin-top: -2px;
}

.SiteEdit div.dropdown__indicators {
	margin-top: -2px;
}
