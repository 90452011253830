.ReactionsPopper {
	background-color: var(--background-color);
	border-radius: 50px;
	padding: 3px;
	top: -10px;
}

.ReactionButton {
	display: flex;
	flex-direction: column;
}

.ReactionButton .reac-count {
    font-family: 'Poppins-SemiBold';
    font-size: 0.75rem;
    line-height:  0.625rem;
    margin-top: 5px;
}
