.NestedPagesMenu {
	overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;
	min-height: 2.25rem;
}

.sub-page-link-cont {
	display: flex;
	align-items: flex-end;
}

.sub-page-link {
	font-size: 1.25rem;
	line-height:  1.25rem;
	padding-bottom: 5px;
	transition-duration: 0.1s;
	border-bottom: 3px solid transparent;
	color: grey;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	white-space: nowrap;
}

.sub-page-link:not(.active):hover {
	border-bottom: 3px solid rgba(var(--color-primary-rgb), 0.6);
}

.sub-page-link.active {
	color: var(--font-color);
	transition-duration: 0.3s;
	border-bottom: 3px solid var(--color-primary);
}
