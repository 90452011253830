.table-cont {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}



/* .table-header th:nth-child(1), .table-body tr td:nth-child(1) {
	width: 45%;
} */

.table-cont table {
	width:100%;
	table-layout:fixed;
	border-spacing: 7px;
    border-collapse: initial;
	height: fit-content;
}

.table-cont tr {
	border: 1px solid #d7d7d7;
	border-left:none;
	border-right:none;
	font-weight:100;
}

.table-cont .table-header thead tr {
	border:none;
	border-left:none;
	font-size:15px;
}

.table-body {
	height: 100%;
	overflow-y:auto;
	overflow-x:hidden;
	margin-top:0;
	border-left:none;
	padding-bottom:10px;
}

.table-cont td {
	text-align:left;
	vertical-align:middle;
	font-weight:300;
	font-size:14px;
	height: auto;
	line-height:14px;
    padding: 0.5em;
	word-break: break-word;
	border: none;
	color: var(--font-color);
}

/*
** -- body
*/

.table-body .infinite-scroll-component {
	padding: 0px!important;
}

/*
** -- CheckBox
*/

.table-checkbox {
	text-align: center !important;
	-webkit-appearance: none;
	z-index: 1;
	position: relative;
}

tr:hover [type="checkbox"]:checked
{
	background-color: #2E8ABA;
	background-color: var(--color-primary);
}

tr:hover [type="checkbox"]:not(:checked), 
tr.active [type="checkbox"]:not(:checked) {
    opacity: 1;
} 

tr:not(.active):not(:hover) [type="checkbox"]:not(.switch):checked::after {
    background-color: #2e8aba;
    background-color: var(--color-primary);
}

.theme-dark .checkbox:not(:checked)::before, .checkbox:checked::before {
	background: var(--background-color-secondary);
}

.dark-bg .checkbox:not(:checked)::before, .checkbox:checked::before {
	background: var(--background-color);
}

.theme-light .dark-bg .checkbox:not(:checked)::before, .checkbox:checked::before {
	background: var(--background-color-darken);
}

/*
** -- Sicky
*/

.table-cont.sticky table {
	/* table-layout: auto; */
}

.table-cont.sticky .table-header {
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--background-color);
}

.table-cont.sticky.dark-bg .table-header {
	background-color: var(--background-color-secondary);
}

.table-cont.sticky .table-body {
	overflow: unset;
	width: 100%;
	z-index: 1;
}

.table-cont.sticky thead th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #e4e4e4;
	background-color: var(--background-color-secondary);
	z-index: 1;
}

.table-cont.sticky.dark-bg thead th {
	background-color: var(--background-color-darken);
}

.table-cont.sticky th:first-child,
.table-cont.sticky td:first-child {
	position: -webkit-sticky;
	position: sticky;
	left: 8px;
	z-index: 2;
	width: 250px;
	display: table-cell;
	background-color: var(--background-color);
	border-right: 0.5rem solid var(--background-color-secondary);
}

.table-cont.sticky th:first-child,
.table-cont.sticky td:first-child {
	background-color: var(--background-color-secondary);
	border-right: 0.5rem solid var(--background-color);
}

.table-cont.sticky.dark-bg th:first-child,
.table-cont.sticky.dark-bg td:first-child {
	background-color: var(--background-color);
	border-right: 0.5rem solid var(--background-color-secondary);
}

.table-cont.sticky th,
.table-cont.sticky td {
	width: 200px;
}

.table-cont.sticky .infinite-scroll-component {
	overflow: unset!important;
}

.table-cont.sticky th:first-child {
	background-color: var(--background-color);
}

.table-cont.sticky.dark-bg th:first-child {
	background-color: var(--background-color-secondary);
}

.table-cont.sticky th:first-child > * {
	visibility: hidden;
}

.table-cont.sticky th:nth-child(2) {
	border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.table-cont input:not([type="checkbox"]) {
	background-color: var(--background-color-secondary);
}

.table-cont.sticky thead th input:not([type="checkbox"]) {
	background-color: var(--background-color-secondary);
}

.table-cont.sticky.dark-bg thead th input:not([type="checkbox"]) {
	background-color: var(--background-color-darken);
}

.table-cont.dark-bg input:not([type="checkbox"]) {
	background-color: var(--background-color);
}
