.EventsList.absolute {
	z-index: 2;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: calc(100% - 30px);
	background-color: var(--background-color-secondary);
	border-radius: 8px;
	box-shadow: 0 0px 11px 2px var(--shadow);
	margin: 15px;
	max-height: 50%;
	overflow: auto;
}

.events-list-ph {
	color: grey;
}
/*
** -- events
*/

.event-loading > div {
	padding: 5px;
	height: 50px;
	width: 100%;
	min-height: 50px;
	border-radius: 6px;
	margin-bottom: 10px;
	margin-top: 10px;
	margin-right: 0;
}

.event-cont {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.event-cont > * {
	display: flex;
	align-items: center;
	height: 100%;
}

.event-date-cont {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	color: rgb(171, 171, 171);
	font-weight: 300;
	max-width: fit-content;
	width: 100%;
}

.event-start-date {
	margin-bottom: 0px;
}

.event-start-date.all-day {
	line-height: 1.125rem;
}

.event-color-bullet {
	border-radius: 100px;
	background-color: #3788d8;
}

.event-content-cont {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.event-cont:not(:last-child) .event-content-cont {
	border-bottom: 1px solid #bfbfbf;
}

.event-name {
	font-weight: 500;
}

.event-worked-on {
	font-weight: 300;
	color: grey;
	font-size: 0.9375rem;
}

.event-worked-on:empty {
	display: none;
}

@media screen and (max-width: 768px) {
	.event-solo {
		padding-bottom: 7rem !important;
	}
}
