.loggin-footer {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: auto;
	padding-bottom: 0px;
	color: white;
	font-size: 0.75rem;
}

.loggin-footer .footer-bar {
	background-color: #00000052;
	border-radius: 50px;
	padding: 0px 10px;
	margin-bottom: 5px;
}

.toggle-language-menu-btn {
	color: white;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.toggle-language-menu-btn .btn-icon {
	font-size: 1.25rem;
}

.language-menu-cont {
	background-color: #ffffff95;
	backdrop-filter: blur(5px);
	color: black;
	padding: 0;
	height: 0px;
	overflow: hidden;
	width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	transition-duration: 0.3s;
	display: flex;
	justify-content: center;
	box-shadow: 0 6px 10px 0px black;
}

.language-menu-cont.open {
	height: 50px;
	padding: 5px 10px;
	transition-duration: 0.6s;
}
