.FormFiller {
	min-width: 500px;
}

.FormFiller .FormSection {
	background-color: var(--background-color);
	box-shadow: none;
	border: none;
	padding: 0;
}

.FormFiller .FormSection .section-content {
	background-color: var(--background-color-secondary);
	border-radius: 12px;
	border: none;
	padding: 1rem 1.375rem;
}

.FormFiller .section-title {
	font-family: "Poppins-Bold";
}

.FormFiller .FormQuestion {
	background-color: var(--background-color-secondary);
	border-radius: 12px;
	border: none;
	padding: 1rem 1.375rem;
}

.FormFiller input,
.FormFiller textarea {
	background-color: var(--background-color);
}

.FormFiller textarea {
	min-height: 150px;
}

.FormFiller .section-text,
.FormFiller .question-text {
	color: grey;
}

.FormFiller .section-text a {
	color: #0d6efd;
}
/*
** -- Options
*/

.FormFiller .OptionsList {
	margin-left: -0.25rem;
}

.FormFiller .option-cont {
	padding-left: 0.25rem;
	border-radius: 6px;
}

.FormFiller .option-cont:not(:last-child) {
	margin-bottom: 0.25rem;
}

.FormFiller .option-cont:hover {
	background-color: rgba(var(--color-primary-rgb), 0.3);
}

.FormFiller .field-error {
	border: 2px solid red !important;
	border-radius: 12px;
}
