
.PostContent {
    word-break: break-word;
	white-space: break-spaces;
}

.PostContent .post-title
{
	font-size: 1.625rem;
	line-height:  1.875rem;
	letter-spacing: -0.5px;
}

.PostContent .post-text
{
	font-size: 0.8125rem;
}

.PostContent .post-text *
{
	color: var(--font-color);
}

.PostContent em {
	font-family: "Poppins-Italic";
}

.PostContent .embbed-iframe {
	display: block;
	width: 100%;
	max-width: 1000px;
	aspect-ratio: 16/9;
	max-height: 60vh;
	border-radius: 6px;
	position: relative;
}

.PostContent .embbed-iframe.loading {
	content: "Chargement de la vidéo";
	content: "";
	background-image: linear-gradient(90deg, var(--background-color) 0%, transparent 50%, var(--background-color));
	animation: embbedLoad 3s infinite ease-in-out alternate-reverse;
}

@keyframes embbedLoad {
	0% {
		background-position-x: 0%;
	}

	100% {
		background-position-x: 500px;
	}
}
