.DocsList {
	width: 100%;
}

.btn-favs-docs {
	height: 38px;
}

.search-bar-cont {
	min-width: 200px;
}

.docs-page-inner-cont,
.my-documents-cont {
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.docs-page-inner-cont .DocsTiles .infinite-scroll-component {
	margin-left: -15px;
	padding: 0;
}

@media (max-width: 700px) {
	.docs-page-inner-cont .DocsTiles .infinite-scroll-component {
		width: 103%;
		padding-right: 1rem;
		margin-left: 0;
		padding-bottom: 4rem;
	}
	.DocsList {
		padding: 0 5% 9rem 5%;
	}

	.DocsList .tile:not(.fixed-tile) {
		width: 100%;
		margin: 0 0 1rem 0;
	}
}
