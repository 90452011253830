.gallery-cont {
	border-radius: 6px;
	overflow: hidden;
}

.gallery-cont > * {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px;
	/* align-items: center; */
	justify-content: center;
	max-height: 100%;
	/* overflow-y: scroll; */
	overflow-x: hidden;
	background-color: var(--background-color-darken);
	
}

.gallery-cont.drag-over > *,
.drag-over {
	background-color: rgba(var(--color-primary-rgb), 0.3);
}
