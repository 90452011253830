.PostsList {
	width: 100%;
}

.PostsList .infinite-scroll-component__outerdiv {
	height: 100%;
	width: 100%;
}

.PostsList .infinite-scroll-component {
	overflow-y: auto;
	overflow-x: hidden;
	gap: 1.5rem;
	padding-right: 0.2rem;
}

.PostsList .infinite-scroll-component:empty:after {
	content: "Aucun élement a afficher";
	color: grey;
	width: 100%;
	text-align: center;
}

@media screen and (max-width: 1000px) {
	.PostsList .infinite-scroll-component {
		gap: 1rem;
		width: 95%;
	}
}

@media screen and (max-width: 700px) {
	.PostsList .infinite-scroll-component {
		width: 103%;
		padding-right: 1rem;
		padding-bottom: 7rem;
	}
}
