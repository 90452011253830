.PagesGroupPage {
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
}

.PagesGroupPage .MenuToggler {
	display: block;
}

.pages-group-title {
	font-size: 3rem;
	color: var(--color-primary);
}

.PagesGroupTiles {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
	padding-bottom: 6rem;
	padding: 0 1.5rem 6rem;
}
.PagesGroupTile {
	border-radius: 24px;
	align-items: center;
	justify-content: center;
	transition-duration: 0.3s;
	max-height: 9em;
	margin: 0 auto 2rem;
	position: relative;
}

@media (min-width: 700px) {
	.PagesGroupTile:hover {
		background-color: var(--color-primary);
		color: var(--color-primary-font-color);
	}
}

.PagesGroupTile .nav-link {
	color: var(--font-color);
	height: 100%;
	width: auto;
	display: block;
	text-align: center;
	padding: auto;
}

.PagesGroupPage .container-icon-nav {
	box-shadow: inset 15px 15px 15px 0 #ddd;
	border-radius: 200px;
	height: 8rem;
	width: 8rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.PagesGroupTile .notif-count {
	padding: 0.1rem;
	min-width: 1.1rem;
	position: relative;
	top: -0.2rem;
	font-size: 0.65rem;
	margin-left: 0.4rem;
	display: inline-block;
	margin-right: 0.3rem;
}

.PagesGroupPage .nav-link-page-icon {
	width: 100%;
	/* height: 3rem; */
	background: none !important;
}

.PagesGroupTiles .nav-link .container-icon-nav .nav-link-page-icon:before {
	font-family: "icons";
	font-size: 6rem;
	color: var(--color-primary);
}

.PagesGroupTiles
	.nav-link.articles
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e800";
}

.PagesGroupTiles
	.nav-link.documents
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e809";
}

.PagesGroupTiles
	.nav-link.charts
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e80b";
}

.PagesGroupTiles
	.nav-link.agenda
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e813";
}

.PagesGroupTiles
	.nav-link.sites
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e826";
}

.PagesGroupTiles
	.nav-link.careers
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e80C";
}

.PagesGroupTiles
	.nav-link.pro_offers
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e80a";
}

.PagesGroupTiles
	.nav-link.offers
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e818";
}

.PagesGroupTiles
	.nav-link.forms
	.container-icon-nav
	.nav-link-page-icon:before {
	content: "\e80d";
}

.PagesGroupPage .nav-link .font-medium {
	color: var(--font-color);
	margin-top: 0.5rem;
}

.PagesGroupPage .nav-link .font-medium span.title {
	opacity: 0.5;
}

.theme-dark .PagesGroupPage .nav-link-page-icon {
	filter: invert(1);
}

.PagesGroupPage .PageHeadBar {
	position: relative;
	margin-bottom: 3rem;
	padding: 1.5rem;
}

.PagesGroupPage .PageHeadBar .site-logo {
	max-height: none;
	height: 80px;
	position: absolute;
	top: 1rem;
	left: 50%;
	transform: translateX(-50%);
}

.PagesGroupPage .MenuToggler {
	width: 28px;
}

.PagesGroupPage .MenuToggler hr {
	margin: 7px 0;
}
