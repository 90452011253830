.FormEditToolbar {
	position: absolute;
	z-index: 1;
	width: 100%;
	display: block;
}

.toolbar-items {
	background-color: white;
	background: var(--background-color-secondary);
	box-shadow: 0 0 3px 0px #0000007f;
	position: absolute;
	text-align: center;
	right: 5px;
	top: 30px;
	width: 40px;
	padding: 5px 0px;
	border-radius: 6px;
	transition-duration: 0.5s;
	/* display: none; */
	z-index: 2;
}

.toolbar-items > div {
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

/*
** -- Responsive
*/

@media (max-width: 600px) {
	.FormEditToolbar {
		position: fixed;
		bottom: 91px;
		left: 0px;
		width: 100%;
	}

	.toolbar-items {
		display: flex !important;
		position: relative;
		right: 0px;
		width: 100%;
		top: 0px !important;
		border-radius: 10px;
		border: 1px solid #c1c1c1;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;
		justify-content: space-around;
	}

	.toolbar-items > div {
		width: 30px;
	}
}
