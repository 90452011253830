
/*
** -- UserTiles
*/

.UserRow {
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 5px;
	font-size: 0.875rem;
}

.UserRow .user-preview .user-infos {
	font-size: 0.875rem;
}

.UserRow:not(:last-child)
{
	margin-bottom: 0.5rem;
}

.UserRow .last-conn {
	font-size: 0.75rem;
	color: grey;
}

.UserRow .online-dot {
	width: 10px;
	height: 10px;
	background-color: rgb(0, 169, 0);
	border-radius: 50%;
}
