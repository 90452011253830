.ContextMenu {
    position: fixed;
    top: -1000px;
    right: -1000px;
    z-index: 10;
}

.react-contexify {
	background-color: var(--background-color);
	color: "red";
}

.react-contexify__item__content {
	color: var(--font-color);
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
	color: var(--color-primary-font-color);
	background-color: var(--color-primary);
}
