.settings-page-cont {
	padding: 0;
}

.SettingsPage {
	overflow: auto;
	padding: 3vh 3%;
}

.settings-list > *:not(:last-child)
{
	border-bottom: 1px solid var(--background-color-secondary);
	padding-bottom: 2rem;
}
