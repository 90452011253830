.page-cont.analytics-page-cont {
	overflow: hidden!important;
	height: 100%;
	padding: 0;
}

.AnalyticsPage {
	padding: 3vh 3%;
	overflow-y: auto;
	overflow-x: hidden;
}

.AnalyticsPage .stats-cont {
	padding: 12px;
	border-radius: 12px;
	background-color: var(--background-color-secondary);
	font-size: 0.9rem;
}
