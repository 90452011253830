.PostsTable .dot {
	padding: 3px;
	border-radius: 50px;
	background-color: var(--color-primary);
} 

.PostsTable .title-cell {
	text-overflow: ellipsis;
    display: block;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
	max-width: 300px;
}

.PostsTable .post-date-cell .priority-picto{
	color: red;
}

.PostsTable .post-date-cell .differed-picto{
	color: rgb(0, 171, 171);
}

.PostsTable .post-likes-cell .likes-popper {
	color: var(--font-color);
	box-shadow: 0px 0px 4px 3px var(--shadow);
}
