.steps-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding: 0.625rem 1.375rem;
	/* background-color: var(--background-color-secondary); */
	border-radius: 10px;
}

.steps-nav > * {
	white-space: nowrap;
	width: 100%;
}

.steps-nav .btn {
	height: 100%;
}

.close-btn-edit {
	position: absolute;
	top: 0;
	right: 0;
	height: auto !important;
	width: auto;
}

.close-btn-edit .Button {
	background: none;
	color: var(--color-primary);
}

.steps-nav.mobile > * {
	width: auto;
}

.steps-nav.mobile .Button {
	width: auto;
	height: auto;
	background: none;
	color: var(--color-primary);
	font-size: 0.6rem;
}

.steps-nav.mobile .Button span {
	display: block;
	font-size: 2rem;
	margin-bottom: 0.2rem;
}

.steps-nav.mobile .Button.disabled {
	color: #999;
}

.steps-nav.mobile .Button .icon-next,
.steps-nav.mobile .Button .icon-prev {
	font-size: 1.4rem;
	margin-top: 0.4rem;
}
.close-btn-edit span {
	font-size: 3rem;
}
