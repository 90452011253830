.FiltersDrawer {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	padding: 15px;
	box-shadow: 0px 5px 4px -2px var(--shadow);
}

.favSiteUnselected {
	background-color: rgb(242, 242, 244) !important;
	color: rgb(0, 0, 0) !important;
}
