.row-handle {
	width: 10px;
	height: 30px;
    cursor: ns-resize;
	background-image: url(../../assets/dragY.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 60%;
}

.theme-dark .row-handle {
	filter: invert(1);
}

.TableCtxMenu {
	position: relative;
	z-index: 3;
}
