.author-preview .user-infos:empty{
	display: none;
}

.author-popper {
	z-index: 90;
	position: fixed;
	min-width: 200px;
	max-width: 400px;
}

.author-popper .user-card.tile {
	width: 100%;
	box-shadow: 0px 0px 5px 1px var(--shadow);
}

.theme-dark .author-popper .user-card.tile {
	background-color: var(--background-color);
}

.author-popper .user-card.tile .user-image {
	width: 50px;
}

.author-preview .published-date {
    font-family: 'Poppins-SemiBold';
	font-size: 0.5625rem;
    line-height:  0.6875rem;
	margin-top: 0.1rem;
}
