.embbed-iframe {
	border-radius: 6px;
}

.embbed-iframe.thumbnail {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.embbed-iframe.cookie-blocked {
	display: block;
	background-size: 60%;
	background-position: center;
	background-repeat: no-repeat;
}

.embbed-iframe .cookies-prompt {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    backdrop-filter: blur(10px);
	padding: 1rem;
	border-radius: 6px;
	overflow: hidden;
}

.cookies-prompt-box {
	background-color: var(--background-color);
	color: var(--font-color);
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	padding: 10px;
}
