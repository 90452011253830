.UserPreviewDrawer {
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80%;
	padding: 20px;
	margin: auto 3%;
	border-radius: 30px;
	background-color: var(--background-color-secondary);
}

.UserPreviewDrawer .user-preview {
	align-items: flex-start;
}

.UserPreviewDrawer .user-image {
	display: block;
	width: 160px;
}

.UserPreviewDrawer .OnlineStatus {
	padding: 5px;
	border-radius: 50px;
	background-color: #00a900 ;
	border: 2px solid #FFFFFFAA;
}

.UserPreviewDrawer .user-infos {
	margin-left: 20px;
}

.UserPreviewDrawer .user-fullname {
	font-size: 3.75rem;
	line-height:  4.375rem;
	text-transform: capitalize;
}

.UserPreviewDrawer .user-work-function {
    font-size: 1.5rem;
    line-height:  1.375rem;
	font-family: "Poppins-ULight";
}

.UserPreviewDrawer .user-work-family {
	font-size: 0.9375rem;
	line-height:  1.25rem;
	font-family: "Poppins-SemiBold";
	margin-top: 10px;
}

.UserPreviewDrawer .user-contacts {
	margin-top: 20px;
}

.UserPreviewDrawer .user-contact > * {
	font-size: 0.9375rem;
}

.UserPreviewDrawer .user-contact {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.UserPreviewDrawer .user-contact a {
	display: flex;
	align-items: center;
	height: 100%;
}

.UserPreviewDrawer .user-contact-icon {
	margin-right: 20px;
	font-size: 1.875rem;
}

.UserPreviewDrawer .user-phone {
	margin-left: 0px;
}

.UserPreviewDrawer .no-contact-info-msg {
	color: grey;
}

.UserPreviewDrawer .user-image {
	margin-bottom: 20px;
}

@media (max-width: 700px) {
	
	.UserPreviewDrawer {
		position: absolute;
		top: 0;
		left: 0px;
		margin: 0;
		width: 100%;
		border-radius: 0;
		height: 100%;
		justify-content: flex-start;
		padding-top: 10vh;
	}

	.users-cont .preview-drawer .close-preview-drawer-btn {
		z-index: 2;
	}

	.UserPreviewDrawer .user-fullname {
		font-size: 2.5rem;
		line-height:  2.7rem;
		margin-bottom: 10px;
	}

	.UserPreviewDrawer .user-preview {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.UserPreviewDrawer .user-image {
		margin-bottom: 20px;
		margin-right: 0!important;
	}

	.UserPreviewDrawer .user-infos {
		margin-left: 0;
	}
}

