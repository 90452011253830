.doc-btn-viewer {
	padding: 1px 5px;
	text-align: center;
	cursor: pointer;
}

.doc-btn-viewer:first-child {
	margin-left: 15px;
}

.doc-btn-viewer_text {
	font-size: 0.5rem;
	line-height: 0.9375rem;
	font-weight: bold;
}

.doc-btn-viewer svg {
	color: #ff4650;
	height: 1.5em;
	vertical-align: 0em;
}
