
.PostPreviewTopBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--background-color-darken);
	z-index: 2;
	margin-bottom: 5px;
	padding-bottom: 5px;
	overflow: hidden;
	width: 100%;
	color: var(--font-color);
	min-height: 32px;
}

.PostPreviewTopBar .close-extended {
	font-size: 1.25rem;
}

.no-extend .PostPreviewTopBar {
	display: none;
}

.PostPreviewTopBar .title {
	opacity: 0;
	transition-duration: 0.5s;
	overflow: hidden;
	word-break: keep-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: initial;
}

.PostPreviewTopBar.show-title .title {
	opacity: 1;
}
@media (max-width: 700px) {

	.PostPreviewTopBar {
		margin-bottom: 0px;
		padding: 0px 5px;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		backdrop-filter: blur(3px);
		background-color: rgba(var(--background-color-rgb), 0.7);
		border-bottom: none;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		z-index: 3;
	}
}
