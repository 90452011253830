.chart-edit-step .tile.chart-tile {
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-color: var(--background-color); */
	margin: 0;
	flex: 1 1 500px;
}

.chart-edit-step .pop.tile.chart-tile {
    width: 70%;
    position: absolute;
    z-index: 3;
    right: 0;
    box-shadow: 0 0 5px 2px var(--shadow);
}

.chart-edit-step .chart-edit-infos input:not(.e-colorpicker) {
	min-width: 200px;
}

.chart-edit-step .props-cont {
	z-index: 1;
}

.chart-edit-step .props-cont > *,
.ChartOptions > * input {
	flex: 1 1 200px;
}

.chart-edit-step .chart-title input {
	min-width: 400px;
	height: 38px;
}

.chart-edit-step .ChartOptions {
	z-index: 0;
}


.chart-edit-step .ChartEditTable {
    z-index: 0;
    position: relative;
}

.ChartOptions .form-input {
	flex: 1 1 200px;
}
