.SitePreview {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.SitePreview .map-cont {
	height: 33vh;
	min-height: 200px;
	z-index: 0;
}

.SitePreview .site-identity {
	/* height: 50%; */
	display: flex;
	gap: 1rem;
}

.SitePreview .site-identity .title {
	font-size: 2.5rem;
	line-height: 2.5rem;
}

.SitePreview .site-logo {
	max-width: 33%;
	max-height: 80%;
}

.SitePreview .location {
	line-height: 1rem;
	font-size: 1rem;
}

.SitePreview .site-contact-icon {
	color: grey;
	font-size: 1.25rem;
}

@media screen and (max-width: 700px) {
	.SitePreview {
		justify-content: flex-start;
	}
	.SitePreview > div:last-child {
		order: 1;
		margin-bottom: 1rem;
		padding-top: 2rem;
	}
	.SitePreview > div:nth-child(2) {
		order: 3;
		margin-top: 0 !important;
	}
	.SitePreview .site-identity,
	.SitePreview .nbr-member-txt {
		padding: 0 2rem;
	}
	.SitePreview .UsersList {
		padding: 0 2rem;
	}
	.SitePreview > div:nth-child(1) {
		order: 2;
	}
	.SitePreview > div:nth-child(1) > div:nth-child(2) {
		position: absolute;
		top: 3rem;
	}
}
