.my-documents-cont .scroll-cont {
	margin: 0px;
}

.my-documents-cont .tile {

	margin-bottom: 10px;
}

.my-documents-cont .doc-preview {
	background-color: var(--background-color-secondary);
	border-radius: 0.5rem;
}
