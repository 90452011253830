@media (max-width: 700px) {
	.FilterMenu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: white;
		z-index: 100;
		overflow-y: auto;
		padding: 4rem 0 4.5rem;
	}
	.FilterMenu .FiltersDrawer {
		box-shadow: none;
	}
	.FilterMenu .SearchBar {
		margin: 0 0.5rem;
		padding: 0;
		position: relative;
	}
	.FilterMenu .SearchBar .search-btn {
		position: absolute;
		right: 0.5rem;
	}
	.SearchBar input {
		border: 1px solid #99999957;
	}
	.FilterMenu .closeBtn {
		position: absolute;
		top: 1.5rem;
		right: 1rem;
		padding: 10px;
	}
	.FilterMenu .footerZone {
		border-top: 1px solid #e0e0e0;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 1rem 1rem 2rem;
		width: 100%;
	}
	.container-searchBtnFilter {
		position: relative;
	}
	.container-searchBtnFilter .icons {
		color: var(--color-primary);
	}
	.numberFilter {
		right: -0.5rem;
		background: var(--color-primary);
		color: white;
		border-radius: 1000px;
		text-align: center;
		position: absolute;
		padding: 0.2rem 0.5rem;
		font-size: 0.5rem;
	}
}
