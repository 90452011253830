.Menu {
	display: flex;
	position: relative;
	width: 100%;
	background-color: #fff;
	background-color: var(--main-menu-bg);
	min-width: 200px;
	width: auto;
	max-width: 250px;
	height: 100%;
	max-height: 100%;
	box-shadow: 0 0px 6px 0px #cbcbcb;
	box-shadow: 0 0px 6px 0px var(--main-menu-shadow);
	z-index: 1;
	transition-duration: 0.2s;
}

.Menu .menu-nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	justify-content: space-between;
}

.Menu .comp-logo {
	width: 90%;
	min-height: 80px;
	max-width: unset;
	height: 80px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
}

.Menu .AdminMenuHead {
	z-index: 4;
	margin-top: 2rem;
}

.Menu .CompanyChanger {
	z-index: 5;
	max-width: 200px;
	max-height: 200px;
	transition-duration: 0.3s;
}

.Menu .menu-links {
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
	align-self: center;
	width: 100%;
	flex-direction: column;
	z-index: 1;
	gap: 0.5rem;
	margin-top: 2rem;
}

.Menu .copyrights {
	font-size: 0.75rem;
	color: grey;
	width: 100%;
}

/* REDUCE */
.Menu .reduce-menu-btn {
	display: none;
}

.Menu:not(.reduced-menu) .show-account-menu-btn {
	display: none;
}

@media screen and (min-width: 1000px) {
	.Menu {
		min-width: 200px;
		max-width: 250px;
	}

	.Menu.reduced-menu {
		min-width: 0px;
		max-width: 150px;
	}

	.Menu .AdminMenuHead {
		max-width: 200px;
		max-height: 200px;
		overflow: hidden;
		transition-duration: 0.3s;
	}

	.Menu.reduced-menu .AdminMenuHead .CompanyChanger {
		max-width: 0px;
		max-height: 0px;
		overflow: hidden;
	}

	.Button.reduce-menu-btn {
		position: absolute;
		right: -10px;
		background-color: var(--main-menu-bg);
		box-shadow: 0px 0px 3px 1px var(--shadow);
		color: var(--color-primary);
		aspect-ratio: 1;
		width: 20px;
		height: 20px;
		padding: 0px !important;
		padding-left: 2px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		transition-duration: 0.5s;
		animation-duration: 0.5s;
		transform: rotate(180deg);
	}

	.Menu.reduced-menu .reduce-menu-btn {
		transform: rotate(0deg);
	}

	.Menu.reduced-menu .site-logo {
		max-width: 90px;
		min-height: 0px;
		height: auto;
	}

	.Menu.reduced-menu .PagesGroup .pages-group {
		padding-right: 3px;
	}

	.Menu.reduced-menu .PagesGroup .pages-group .nav-link-page-icon {
		display: flex;
	}

	.Menu.reduced-menu .PagesGroup.open > .pages-group {
		padding-left: 10px;
		margin-left: 10px;
	}

	.Menu.reduced-menu .PagesGroup .pages-group .nav-link-infos {
		margin-left: 5px;
	}

	.Menu.reduced-menu .PageButton .nav-link-page-title {
		display: none;
	}

	.Menu.reduced-menu .user-image {
		margin-right: 0px !important;
	}

	.Menu.reduced-menu .accounts-menu:not(.open) .account-menu {
		flex-direction: column;
	}

	.Menu.reduced-menu .accounts-menu:not(.open) .account-menu-infos {
		display: none;
	}

	.Menu.reduced-menu .accounts-menu.open {
		min-width: 200px;
	}

	.Menu.reduced-menu .accounts-menu.open .show-account-menu-btn {
		display: none;
	}

	.show-account-menu-btn {
		font-size: 20px;
	}
}

.account-menu-infos .icons {
	color: grey;
}

/* Notifs */

.notif-count {
	background-color: red;
	width: 1.2rem;
	height: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 300px;
	font-size: 0.875rem;
	line-height: 0.9375rem;
	color: white !important;
}

.notif-count:empty {
	display: none;
}

/* ----------------- */

.Menu .menu-links > *:not(:last-child),
.Menu .pages-group > *:not(:last-child) {
	margin-right: 0px;
}

.Menu .menu-footer {
	align-self: flex-start;
	margin-top: auto;
}

/*
** -- Alerts
*/

.Menu .alerts-cont {
	background-color: var(--main-menu-nav-links-cont-bg);
	border-radius: 60px;
	font-size: 0.8125rem;
	padding: 2px 10px;
}

.Menu .alerts-cont.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.Menu .alerts-button {
	display: flex;
	font-size: 0.875rem;
	align-items: center;
	z-index: 0;
}

.Menu .alerts-button .notif-count {
	margin-left: 5px;
}

@media (max-width: 1000px) {
	.Menu {
		width: unset;
		max-width: none !important;
		top: 0px;
		left: -100%;
		height: 100%;
		transition-duration: 0.5s;
	}

	.Menu.open {
		left: 0px;
	}

	.Menu .MenuToggler {
		position: absolute;
		left: 10px;
		top: 15px;
		display: block;
	}
}

@media screen and (max-width: 700px) {
	.Menu {
		width: 100%;
		z-index: 2000;
	}
}

@media screen and (min-width: 701px) and (max-width: 1000px) {
	.Menu {
		position: absolute;
		width: 100%;
	}
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
}
