.tutorials-page-cont {
	display: block;
	overflow: auto;
}

.GroupsList {
	display: flex;
	width: 100%;
	overflow-y: hidden;
	overflow-x: scroll;
	padding-bottom: 0.5rem;
}

.GroupTile {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	padding: 5px;
	text-align: center;
	width: 128px;
	height: 128px;
	aspect-ratio: 1;
}

.GroupTile:hover {
	cursor: pointer;
	filter: brightness(0.9);
}

.GroupTile .helper-group-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-size: 40px;
	color: var(--color-primary);
}

/*
** -- Summary
*/

.HelperMenu {
	height: auto;
	overflow: scroll;
}

.HelperGroup .helper-group-header {
	background-color: var(--background-color-secondary);
	padding: 10px 10px;
	border-radius: 12px;
}

.HelperGroup .helper-group-description {
	font-size: 12px;
}

.helper-group-arrow {
	width: fit-content;
	height: fit-content;
	transition-duration: 0.3s;
}

.HelperGroup[data-open='true'] > .helper-group-header > .helper-group-arrow {
	rotate: 90deg;
}

.HelperGroup > .HelperGroup {
	margin-bottom: 0px;
}

.HelperGroup .group-childs {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	border-radius: 6px;
	margin-left: 1rem;
	padding-top: 0.25rem;
	font-size: 14px;
}

.HelperGroup[data-open='true']  .group-childs {
	margin-bottom: 0.5rem;
}

/*
** -- Helper Item
*/

.HelperItem {
	background-color: var(--background-color-secondary);
	padding: 10px 10px;
	border-radius: 12px;
	font-size: 14px;
}

.helper-item-description {
	font-size: 12px;
}

.helper-items-links .Button {
	font-size: 20px;
	color: var(--color-primary);
}
