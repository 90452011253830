
.TableHead th
{
    font-family: "Poppins-Bold";
    font-size: 0.625rem;
    line-height:  0.7rem;
	color: #2E8ABA;
	color: var(--color-primary-text);
	text-transform: uppercase;
	padding: 0.5em;
	border: none;
}

.TableHead .col-title.sort {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.TableHead .asc::after, .TableHead .desc::after
{
	content: "";
	display: inline-block;
	margin-left: 4px;
	border-bottom: 6px solid #2e8aba;
	border-bottom: 6px solid var(--color-primary);
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
}

.TableHead .sort:not(.asc):not(.desc)::after{
	content: "-";
	margin-left: 4px;
	color: #2e8aba;
	color: var(--color-primary);
}

.TableHead .desc::after{
	transform: rotate(180deg);
}

.TableHead .asc::after{
	transform: rotate(0deg);
}

.table-cont.dark-bg table.TableHead {
	background-color: var(--background-color-secondary);
}

/*
** -- checkbox
*/

.TableHead th .checkbox:not(:checked) {
    border: 1px solid #d8d8d8 !important;
	border: 1px solid var(--color-primary)!important;
}

/*
** -- Draggable
*/

.col-handle {
    height: 10px;
    position: relative;
    width: 15px;
	cursor: ew-resize;
	background-image: url(../../assets/dragX.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.theme-dark .col-handle {
	filter: invert(1);
}
