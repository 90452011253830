.comment-date {
	font-size: 0.625rem;
	color: rgb(118, 118, 118);
}

.CommentMenu .comment-menu-items {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 2px;
}

.CommentMenu .comment-menu-items > div {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: grey;
	padding: 2px;
	border-radius: 3px;
}

.nbre_comments {
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;
	text-transform: lowercase;
	padding: 0.5rem 0;
}

.nbre_comments.mobile {
	font-weight: bold;
	text-align: left;
}

.nbre_comments_text {
	font-size: 0.85rem;
	margin-top: -0.6rem;
}

.nbre_comments svg {
	padding-right: 0.5rem;
}

.CommentMenu .comment-menu-items > div:not(:first-child) {
	margin-left: 0.5rem;
}

.CommentMenu .comment-menu-items > div:hover {
	background-color: var(--background-color);
	color: var(--font-color);
}

.wrapper-mobile-commentSender {
	position: absolute;
	bottom: -84%;
	width: 100%;
	height: 93%;
	background: #fff;
	transition: bottom 0.3s;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.wrapper-mobile-commentSender.open {
	bottom: 0;
}

.container-commentSender {
	background: #fff;
}

.wrapper-mobile-commentSender .showCommentSenderBtn {
	position: absolute;
	top: -1rem;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
}

.wrapper-mobile-commentSender .showCommentSenderBtn .text {
	font-size: 0.65rem;
	position: absolute;
	bottom: -1rem;
	width: 100%;
	text-align: center;
	color: var(--color-primary);
}

.wrapper-mobile-commentSender .showCommentSenderBtn:after {
	content: "";
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	width: 3.9rem;
	height: 1.95rem;
	background: rgba(255, 255, 255, 1);
	border-radius: 1000px 1000px 0 0;
	clip-path: inset(0 0 19% 0);
}

.wrapper-mobile-commentSender .showCommentSenderBtn .Button {
	width: 3rem;
	height: 3rem;
	position: relative;
	z-index: 2;
	margin: 0 auto 0.3rem;
	box-shadow: 0px 6px 9px #0000003d;
}

.wrapper-mobile-commentSender .showCommentSenderBtn .Button:hover {
	filter: none;
}
