.DocUploader {
	border-right: 1px solid #999;
	padding-right: 3rem;
	margin-right: 3rem;
	width: 50%;
	flex: 1;
}

.DocUploader .switchElement {
	color: #777;
}

.DocUploader .file-info {
	color: #777;
	font-size: 0.8rem;
	font-style: italic;
}

.upload-container {
	position: relative;
}
.upload-container .icon {
	position: absolute;
	font-size: 3.5rem;
	left: 50%;
	width: 3.5rem;
	overflow: hidden;
	margin-left: -1.75rem;
	top: 3rem;
}

.DocUploader .placeholder {
	margin-top: 4rem;
}

.DocUploader .confirm-btn {
	position: absolute;
	bottom: -5rem;
	width: 20rem;
	left: 50%;
	transform: translateX(-10rem);
}

.DocUploader .drop-cont {
	position: relative;
	height: 204px;
	background-color: var(--background-color);
	border-radius: 25px;
}

.DocUploader .drop-cont .placeholder {
	pointer-events: none;
}

.DocUploader .drop-cont .file-input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
	z-index: 1;
}

.DocUploader .drop-cont.error {
	border: 2px solid red;
}

.DocUploader .DocPreview iframe {
	width: 100%;
	height: 40vh;
	border-radius: 6px;
	position: relative;
}

.DocUploader .DocPreview {
	position: relative;
}

.DocUploader .DocPreview .loading-frame {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	overflow: hidden;
	z-index: 1;
}

.DocUploader .DocPreview .document-tile {
	background-color: var(--background-color);
	margin: 0;
	width: 100%;
}

.DocUploader .DocPreview .too-heavy-msg {
	color: grey;
}

@media screen and (max-width: 600px) {
	.DocUploader {
		width: 100%;
		border-right: none;
		padding-right: 0;
		margin-right: 0;
	}
	.DocUploader .confirm-btn {
		bottom: -3rem;
	}
	.upload-container .icon {
		top: 1rem;
	}
	.DocUploader .drop-cont {
		height: 150px;
	}
	.DocUploader .DocPreview iframe {
		height: 30vh;
	}
}
