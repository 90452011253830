
.posts-list-widget-cont {
	--tile-margin: calc(30px);
	--nbr-rows: 10;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding-right: 5px;
}

.posts-list-widget-cont {
	display: flex;
	flex-direction: column;
}

.posts-list-widget-cont .post-tile
{
	width: 100%;
	margin: 0;
	height: 180px;
	margin-bottom: 10px;
}
