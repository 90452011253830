.tabs-cont {
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	overflow: hidden;
	height: fit-content;
	background-color: var(--background-color-secondary);
}

.tabs-cont.border {
	border: 2px solid var(--background-color-darken);
}

.tabs-head {
	display: flex;
	justify-content: space-between;
	overflow-y: hidden;
	overflow-x: auto;
	padding-bottom: 3px;
	min-height: 40px;
}

.tabs-head .tab-title {
	background-color: var(--background-color-secondary);
	width: 100%;
	text-align: center;
	padding: 5px 10px;
	transition-duration: 0.3s;
	cursor: pointer;
	color: rgba(var(--font-color-rgb), 0.8);
	border-bottom: 2px solid var(--background-color-darken);
	white-space: nowrap;
	/* text-overflow: ellipsis;
	overflow: hidden;
	word-break: keep-all; */
}

.light-bg .tabs-head .tab-title {
	background-color: var(--background-color);
}

.tabs-head .tab-title.active {
	text-overflow: unset;
	overflow: visible;
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

/* 
.tabs-head .tab-title:first-child
{
	border-top-left-radius: 20px;
}

.tabs-head .tab-title:last-child
{
	border-top-right-radius: 20px;
} */

.tabs-head .tab-title:not(:last-child) {
	border-right: 1px solid var(--background-color-darken);
}

.tabs-body {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 1rem;
	background-color: var(--background-color-secondary);
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
}

.tabs-cont.allow-overflow .tabs-body {
	overflow: unset;
}

.tabs-body .tab:not(.active-tab) {
	display: none;
}

.tab-group {
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 15px 20px;
}

.tabs-cont .table-body tr:not(:hover) {
	transition-duration: 0.3s;
	background-color: var(--background-color);
	cursor: pointer;
}

@media screen and (max-width: 1000px) {
	.tabs-body {
		padding: 1rem;
	}
}

@media screen and (max-width: 700px) {
	.tabs-body {
		padding: 0.5rem;
	}
}
