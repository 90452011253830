.EditButtons button {
	flex: 1 1 120px;
}

@media screen and (min-width: 800px) {
	.post-preview-cont.extended .EditButtons button {
		flex: 0 1 140px;
	}
}

@media screen and (max-width: 700px) {
	.post-preview .popupReject {
		position: fixed;
		top: 7.4rem;
		left: 20px;
		width: 90%;
	}
}
