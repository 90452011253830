.FormSection {
	padding: 1em;
	border-radius: 10px;
	margin-bottom: 10px;
	/* box-shadow: 0px 0px 3px 0px #0000007f; */
	background-color: #eeeeeeb0;
	background-color: var(--background-color-secondary);
	border-left: 4px solid #eeeeeeb0;
	border-left: 4px solid var(--background-color-secondary);
	transition-duration: 0.1s;
}

.FormSection.active {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border-left: 4px solid #00f;
	border-left: 4px solid var(--color-primary);
	transition-duration: 0.2s;
}

.FormSection .section-content {
	border-radius: 6px;
	padding: 1em;
	background-color: white;
	background-color: var(--background-color);
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	border-left: 6px solid white;
	border-left: 6px solid var(--background-color);
	transition-duration: 0.1s;
}

.FormSection .section-title {
	font-size: 1.875rem;
	line-height: 1.2;
}

.form-infos {
	color: #808080;
	font-size: 0.75rem;
	text-transform: uppercase;
}
