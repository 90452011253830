.company-page-cont {
	padding: 0;
}

.CompanyPage {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.CompanyPage.scrolled .infos-cont {
	height: 120px;
}

.CompanyPage .CompanyBanner {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 30vh;
}

.CompanyPage .infos-cont {
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	backdrop-filter: blur(4px);
}

.CompanyPage .infos-cont-bg {
	display: flex;
	align-items: center;
}

.CompanyPage .site-logo {
	width: 30%;
	height: auto;
	padding: 15px;
}

.CompanyPage .company-name {
	font-size: 2.5rem;
	width: auto;
	text-transform: uppercase;
}

.CompanyPage .NestedPagesMenu {
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: var(--background-color);
	padding: 15px 0px;
}

.CompanyPageBody {
	background-color: var(--background-color);
	position: relative;
}
