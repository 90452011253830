
.send-image-cont
{
	display: flex;
	width: 100%;
	min-height: 100px;
	align-items: center;
	justify-content: center;
	padding: 13px;
	position: relative;
	background-color: white;
	background-color: var(--background-color);
	border-radius: 6px;
	overflow: hidden;
	text-align: center;
}

.ImageSender .sender-title {
	font-size: 1rem;
}

.ImageSender .sender-desc {
	color: grey;
	font-size: 0.875rem;
}

.ImageSender:not(.hasImage) .send-image-cont .image-preview {
	display: none!important;
}

.ImageSender.hasImage .send-image-cont::after
{
	display: none;
}

.send-image-cont .image-input,
.send-image-cont .image-overlay,
.send-image-cont .loading-cont {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.send-image-cont .loading-cont {
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(5px);
	background-color: rgba(var(--background-color-rgb), 0.7);
}

.send-image-cont .image-input
{
	z-index: 3;
	opacity: 0;
	cursor: pointer;
}

.send-image-cont .image-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	backdrop-filter: blur(5px);
	background-color: rgba(var(--background-color-rgb), 0.7);
	color: var(--font-color);
	opacity: 0;
	transition-duration: 0.2s;
}

.ImageSender.hasImage:hover .image-overlay {
	opacity: 1;
}

.send-image-cont .image-preview {
	width: 100%;
	background-size: contain;
	height: 150px;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
	display: none;
}

.ImageSender.hasImage .image-preview {
    display: block;
	min-height: 250px;
}

.ImageSender .delete-image {
	display: none;
	background-color: var(--background-color);
	align-items: center;
	background-color: #c90000;
	color: white;
	font-weight: bold;
}

.ImageSender.hasImage:hover .delete-image
{
	display: flex;
}

.ImageSender .placeholder {
	background-color: transparent;
	color: var(--font-color);
}
