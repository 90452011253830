.page-cont.contacts-page-cont {
	padding-bottom: 0px;
}

.ContactsPage {
	padding-bottom: 0px;
}

.users-cont {
	height: 100%;
	width: auto;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.users-cont .infinite-scroll-component__outerdiv {
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.users-cont .users-list.infinite-scroll-component {
	margin-left: -12.5px;
	padding: 0;
	padding-bottom: 2rem;
}

.loader_users {
	position: relative;
}

.loader_users:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	top: 0;
	left: 0;
}

.loader_users .user-card {
	min-height: 200px;
}

.container_user_tiles {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	align-items: center;
}
