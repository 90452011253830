.ChartEditTable {
	display: flex;
	overflow: auto;
	flex-direction: column;
}

.ChartEditTable .table-cont {
	max-height: 30vh;
}

.ChartEditTable .table-cont table {
	background-color: var(--background-color-secondary);
}

.ChartEditTable table td {
	border: none;
	padding: 0.25rem;
}

.ChartEditTable th:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.ChartEditTable th:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.ChartEditTable ::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.ChartEditTable .table-add-btn {
	aspect-ratio: 1;
	width: 32px;
}

/*
** -- color picker
*/

.ChartEditTable .e-colorpicker-wrapper {
	align-self: center;
	position: relative;
	background-color: #0000;
    border: none;
	height: 18px;
    width: 25px!important;
}

.ChartEditTable .e-split-colorpicker, .e-selected-color
{
    background-image: none!important;
	background-color: transparent!important;
}


.ChartEditTable .e-split-colorpicker .e-split-preview {
	border-radius: 50%!important;
	width: 18px;
	height: 18px;
	box-shadow: inset 0px 0px 2px 0px #0000002e;
}

.ChartEditTable .e-colorpicker-wrapper .e-dropdown-btn {
	position: absolute;
	z-index: 10;
	left: 5px;
	opacity: 0;
	top: 1px;
}

.ChartEditTable .e-split-btn-wrapper .e-dropdown-btn:active {
    z-index: 10!important;
}
