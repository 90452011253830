.AppFunctionsGroup {
	background-color: var(--background-color);
	padding: 0.5rem;
	border-radius: 6px;
}

.AFGroupChilds {
	border-top: 1px solid var(--background-color-secondary);
}

.AppFunctionsGroup .SwitchInput {
	line-height:  1.125rem;
	font-size: 0.875rem;
}
