button.btn.import-btn {
	padding: 1rem;
	border: 1px dotted grey;
}

.FilesInput {
	background-color: var(--background-color);
	position: relative;
	border-radius: var(--radius-1);
}

.FilesInput.over {
	border: 1px solid red;
}

.FilesInput .FI-placeholder {
	cursor: pointer;
}

.FilesInput .FI-placeholder .upload-btn {
	border-radius: 100px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.FilesInput input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid blue;
	opacity: 0;
	cursor: pointer;
}
