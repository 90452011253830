.UsersTable {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.post-title-cell {
	text-transform: capitalize;	
}

@media screen and (max-width: 650px) {

	.post-role-cell {
		display: none;
	}
}

@media screen and (max-width: 800px) {

	.post-wfam-cell,
	.post-wfunc-cell {
		display: none;
	}
}
