.RouterPrompt {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 90;
	left: 0;
	background-color: rgba(var(--background-color-rgb), 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15%;
}

.RouterPrompt .CloseEditToast {
	background-color: var(--background-color-secondary);
	padding: 1rem;
	border-radius: 6px;
	box-shadow: 0px 0px 10px 2px var(--shadow);
}

@media screen and (max-width: 1000px) {
	.RouterPrompt {
		padding-left: 0;
	}
}
