.AssignRow {
	display: flex;
    align-items: stretch;
	background-color: var(--background-color-secondary);
	border-radius: 12px;
	/* border: 1px solid rgb(174, 174, 174); */
	cursor: pointer;
	font-size: 0.875rem;
	position: relative;
}

.AssignRow.active {
	border: 1px solid var(--color-primary);
	cursor: default;
}

.AssignRow:not(:last-child) {
	/* margin-bottom: 0.5rem; */
}

.AssignRow.root-assign:not(:last-child) {
	/* margin-bottom: 1rem; */
}

.AssignRow .row-head {
	/* width: 100px; */
	background-color: rgba(var(--color-primary-rgb), 0.5);
	color: var(--color-primary-font-color);
	display: flex;
    align-items: center;
    justify-content: center;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.AssignRow .row-type {
	padding: 2px;
	/* font-size: 0.75rem; */
	font-size: 0rem;
}

.AssignRow .assign-drop {
	width: 200px;
}

.AssignRow.active .row-head {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.AssignRow.root-assign {
	padding: 10px;
}

.AssignRow.root-assign > .row-head {
	display: none;
}

.AssignRow .row-body {
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	gap: 0.5rem;
}

.AssignRow .row-body .row-body-head {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* .AssignRow .AssignRow:before {
	content: "";
	display: flex;
	align-items: center;
	border: 6px solid transparent;
	border-left: 6px solid var(--color-primary);
	position: absolute;
	left: 2px;
	top: calc(50% - 7px);
} */

.AssignRow:not(.root-assign) .AssignRow:before {
	content: "";
	display: flex;
	align-items: center;
	border: 2px solid transparent;
	border-right: 0px solid blue;
	border-left: 6px solid var(--color-primary);
	position: absolute;
	left: -13px;
	top: calc(50% - 0px);
	width: 16px;
	background-color: var(--color-primary);
	height: 1px;
}

.AssignRow .row-body .row-buttons {
	align-items: center;
	display: none;
}

.AssignRow.active .row-buttons {
	display: flex;
}

.assign-drop .dropdown__control {
	max-width: 100%;
    border: 1px solid #ccc;
}

.AssignRow .AssignRow .row-body {
	background-color: var(--background-color);
}

.AssignRow .AssignRow .AssignRow .row-body {
	background-color: var(--background-color-secondary);
}

.AssignRow  .AssignRow .AssignRow .AssignRow .row-body {
	background-color: var(--background-color);
}

@media screen and (max-width: 700px) {
	.AssignRow {
		font-size: 0.75rem;
	}

	.AssignRow .row-type {
		width: 0px;
		padding: 2px;
		color: transparent;
		overflow: hidden;
	}
}
