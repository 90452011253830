@media screen and (max-width: 700px) {
	.GroupsTable .defaulthexacolor-col
	{
		display: none;
	}
}

@media screen and (max-width: 500px) {
	.GroupsTable .description-col,
	.GroupsTable .defaulthexacolor-col
	{
		display: none;
	}
}
