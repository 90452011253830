.AssignResume {
	display: flex;
	align-items: stretch;
	background-color: var(--background-color-secondary);
	border-radius: 12px;
	/* border: 1px solid rgb(174, 174, 174); */
	cursor: pointer;
	font-size: 0.875rem;
	padding: 10px;
}

.AssignationList .AssignResume:not(.root-assign) {
	margin-bottom: 0;
	padding: 0;
}

.AssignResume .AssignationList {
	gap: 0.2rem;
}

.AssignResume .assign-label {
	margin-bottom: 0;
	border: 1px solid rgba(var(--color-primary-rgb), 0.5);
	border-left: 6px solid var(--color-primary);
	border-radius: 6px;
	border-bottom-left-radius: 6px;
	height: -moz-fit-content;
	height: fit-content;
	padding: 4px 10px;
	position: relative;
	background-color: rgba(var(--color-primary-rgb), 0.2);
	color: black;
	margin-left: 5px;
	margin-bottom: 5px;
}

.AssignResume.root-assign > .assign-label {
	margin: 0;
}

.AssignResume .assign-label:before {
	content: "";
	display: flex;
	/* height: 100%; */
	align-items: center;
	border: 6px solid transparent;
	border-left: 6px solid var(--color-primary);
	position: absolute;
	left: 0px;
	top: calc(50% - 7px);
}
