.charts-cont {
	display: flex;
	overflow: hidden;
}

.ChartsTiles {
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	align-items: flex-start;
	width: 100%;
}

.tile.chart-tile {
	width: calc(50% - 25px);
}

.ChartEditDrawer .edit-drawer-inner-cont {
	max-width: unset;
}

@media screen and (max-width: 700px) {
	.tile.chart-tile {
		width: calc(100% - 25px);
		align-items: center;
		justify-content: center;
	}
	.ChartsTiles {
		padding-bottom: 7rem;
	}
}
