.cont-serv-error {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: var(--font-color);
	padding: 10px;
	text-align: center;
	background-color: var(--background-color);
}

.broken-logo {
	width: 100%;
	height: 50vh;
	max-height: 300px;
	background-image: url("./broken-logo.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	filter: invert(1);
	opacity: 0.1;
}

.theme-dark .broken-logo {
	opacity: 0.2;
}

.cont-serv-error .error-title {
	border-bottom: 1px solid white;
	margin-bottom: 10px;
	padding: 10px 20%;
	font-size: 1.25rem;
	line-height: 1rem;
}
