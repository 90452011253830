.panel {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	padding-left: 5rem;
}

.panel-wrapper {
	border-radius: 6px;
	background: #fff;
	padding: 2.5rem 3rem;
	position: relative;
	width: 40rem;
}

.panel-cross {
	color: #fff;
	position: absolute;
	top: -2.5rem;
	right: -2rem;
	font-size: 2rem;
	transition: all 0.3s ease-in-out;
}

.panel-cross:hover {
	cursor: pointer;
	color: #ccc;
}

.panel-form .panel-form-btn {
	background: var(--color-primary);
	color: #fff;
}

.panel .drawer-title {
	margin-left: 0;
}

.panel-form-dropdown div.dropdown__control {
	background: var(--input-color);
}
