.CustomPagesNames {
	max-width: 1000px;
}

.theme-dark .IconsSelect img{
	filter: invert(1);
}

@media screen and (max-width: 750px) {
	.PageCustomInfos {
		flex-wrap: wrap;
		padding: 8px;
		border-radius: 6px;
		background-color: #dddddd;
	}

	.theme-dark .PageCustomInfos {
		background-color: #151515;
	}
}
