.CategoryEditDrawer {
	position: absolute;
	top: 0px;
	left: 0px;
	width: calc(100% + 20px);
	height: calc(100% + 20px);
	background-color: var(--background-color);
	padding: 10px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
}

.CategoryEditDrawer .edit-title {
	font-family: "Poppins-ULight";
	font-size: 1.625rem;
	line-height:  1.875rem;
}
