.container-uploadFiles {
	max-width: 80%;
	margin: 3rem auto 0;
	position: relative;
}

.container-uploadFiles h2 {
	font-weight: lighter;
	text-align: center;
	margin-bottom: 4rem;
	min-height: 3rem;
	font-family: "Poppins-ULight";
}

.ExistingsDocs {
	flex: 1;
}

.ExistingsDocs .DocsList {
	height: 40vh;
}

.ExistingsDocs .SearchBar {
	background-color: var(--background-color);
}

.UploadDoc .upload-btn {
	line-height: 0rem;
	padding: 10px;
	font-size: 1.25rem;
	border-radius: 100px;
}

.DocsList .del-btn {
	font-size: 1rem;
	margin-left: 2px;
}
