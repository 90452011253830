.GroupTile
{
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	width: calc((100% / 3) - (var(--tile-margin) * 2));
}

.GroupTile .group-name {
	font-family: "Poppins-Bold";
	text-transform: uppercase;
}

.GroupTile .color-dot
{
	padding: 5px;
	border-radius: 50%;
}

.GroupTile .group-desc {
	font-size: 0.75rem;
	line-height:  0.875rem;
	color: grey;
	font-family: "Poppins-Italic";
}

.GroupTile .members-txt {
	font-family: "Poppins-Light";
	text-transform: uppercase;
	font-size: 0.75rem;
	line-height:  0.875rem;
}

@media screen and (max-width: 550px) {
	.GroupTile {
		width: calc((100%) - (var(--tile-margin) * 2));
	}
}

@media screen and (min-width: 550px) and (max-width: 800px) {
	.GroupTile {
		width: calc((100% / 2) - (var(--tile-margin) * 2));
	}
}

@media screen and (min-width: 1700px) {
	.GroupTile {
		width: calc((100% / 4) - (var(--tile-margin) * 2));
	}
}
