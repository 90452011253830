.Table .MuiTableHead-root th {
	font-family: "Poppins-Bold";
    font-size: 0.625rem;
    line-height:  0.7rem;
	color: #2E8ABA;
	color: var(--color-primary-text);
	text-transform: uppercase;
	padding: 0.5em;
	border: none;
	text-align: center;
	z-index: 2;
}


.Table .MuiTableCell-head,
.Table .MuiTableHead-root tr {
	background-color: var(--background-color)!important;
}

.Table .MuiTableCell-root {
	border-bottom: none;
}

/*
** -- checkbox
*/

.Table .MuiTableHead-root th input[type="checkbox"]:not(:checked) {
    border: 1px solid #d8d8d8 !important;
	border: 1px solid var(--color-primary)!important;
}
