.MyOnlyall .post-preview-cont.extended {
	border-radius: 6px;
	border: 6px solid var(--background-color-secondary);
}

.MyOnlyall .post-preview-cont.extended .post-preview {
	padding: 1rem;;
}

.myonlyall-page-cont,
.myonlyall-page-cont .page-cont {
	overflow: hidden;
}
