.CompanyChanger {
	z-index: 4;
}

.CompanyChanger .dropdown__control {
    background-color: var(--background-color);
    border: 1px solid var(--background-color-darken);
    width: 180px;
    max-width: 180px;
    min-width: 100%;
}

.theme-dark .CompanyChanger .dropdown__control {
    background-color: var(--background-color-secondary);
    border: 1px solid var(--background-color-darken);
}

.CompanyChanger .errors-cont {
	line-height:  0.875rem;
	font-size: 0.875rem;
}
