.PageHeadBar {
	position: relative;
	z-index: 3;
	width: 100%;
	margin-bottom: 0.5rem;
}

.headbar-add-btn {
	font-size: 0.875rem !important;
}

.visibily-hidden {
	visibility: hidden;
}

.PageHeadBar .search-bar-cont {
	width: 100%;
	height: 100%;
}

.PageHeadBar .search-bar-cont input,
.PageHeadBar .btn {
	min-width: 30px;
	font-size: 0.75rem;
	height: 100%;
}

.headbar-left-cont {
	display: flex;
	margin-left: auto;
	flex-wrap: wrap;
	width: 100%;
	justify-content: flex-end;
}

.PageHeadBar .categories-list {
	max-height: 500px;
	overflow: auto;
}

.headbar-left-cont > *:not(.search-cont) svg {
	font-size: 0.875rem;
}

/*
** -- Filters
*/

.PageHeadBar .FiltersDrawer {
	position: absolute;
	width: 100%;
	top: 100%;
}

.PageHeadBar .reset-filters-btn {
	line-height: 0;
	letter-spacing: 0;
	width: 14px;
	height: 14px;
	background-color: #0000004a;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.PageHeadBar .reset-filters-btn svg {
	font-size: 0.625rem;
}

/*
** -- customs btns
*/

.customs-btns-cont > * {
	width: 100%;
}

/* .PageHeadBar .site-logo {
	display: none;
} */

@media screen and (max-width: 500px) {
	.add-btn-title {
		display: none;
	}

	.PageHeadBar .site-logo {
		display: block;
		height: 30px;
		max-height: 30px;
		overflow: hidden;
		margin-bottom: 3px;
	}

	.PageHeadBar .page-title {
		display: none;
	}

	.headbar-right-cont {
		width: 100%;
	}
}

@media screen and (max-width: 700px) {
	.PageHeadBar {
		margin-bottom: 0.5rem;
	}

	.headbar-left-cont {
		flex: 1 1 100% !important;
	}

	.headbar-left-cont > * {
		flex: 1 1 50px;
	}

	.search-bar-cont {
		width: auto;
	}

	.sites-drop-cont div.dropdown__control {
		width: 100%;
		max-width: 100% !important;
	}

	.add-post-btn {
		order: 1;
	}

	.page-title,
	.PageHeadBar .page-title {
		font-size: 1.875rem;
		line-height: 1.875rem;
	}
}

@media (max-width: 1000px) {
	.PageHeadBar .MenuToggler {
		display: block;
	}
}

@media (max-width: 700px) {
	.MobileHeaderBar {
		justify-content: space-between;
		padding: 0.5rem 5% 0.6rem;
		align-items: center;
		background: #fff;
		margin-bottom: 1rem;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	.MobileHeaderBar .MenuToggler {
		display: block;
		width: 25px;
		top: -7px;
		position: relative;
	}
	.MobileHeaderBar .filter {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: 90000;
	}
	.MobileHeaderBar .first {
		align-items: flex-end;
	}
	.MobileHeaderBar .site-logo {
		height: 2.5rem;
	}

	.MobileHeaderBar .head-bar-btn-cont {
		margin: initial !important;
	}
	.MobileHeaderBar .icon-search {
		height: 1.8rem;
		top: 0.2rem;
		position: relative;
	}
	.MobileHeaderBar .MenuToggler hr {
		margin: 7px 0;
	}
	.MobileHeaderBar .Button {
		padding: 0.6rem 0.7rem !important;
	}
	.MobileHeaderBar .Button svg {
		height: 1.2rem;
	}
	.empty_element {
		width: 5rem;
	}
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
	.page-title,
	.PageHeadBar .page-title {
		font-size: 2.8125rem;
		line-height: 2.8125rem;
	}

	.search-bar-cont {
		width: auto;
	}
}
