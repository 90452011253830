.EditPreview {
    background-color: var(--background-color);
    width: fit-content;
	max-width: 100%;
	min-width: 300px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    z-index: 2;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    padding: 10px;
	box-shadow: 0px 0px 5px 4px var(--shadow);
	display: flex;
	flex-direction: column;
}
