.markdown-editor {
	position: relative;
	z-index: 0;
}

.markdown-editor.no-toolbar .toastui-editor-ww-container {
	border-radius: 6px;
}

.markdown-editor.no-toolbar .toastui-editor-toolbar {
	display: none;
}

.markdown-editor .placeholder {
	pointer-events: none;
	user-select: none;
	cursor: default;
	background-color: transparent;
	display: contents;
}

.toastui-editor-defaultUI {
	border: none;
	background-color: var(--input-color);
}

.toastui-editor-main-container * *:not(.placeholder) {
	color: #fff;
	color: var(--font-color) !important;
}

.toastui-editor-defaultUI .ProseMirror {
	max-height: 500px;
}

.toastui-editor-defaultUI-toolbar {
	border-radius: 6px 6px 0 0;
	background-color: #f7f9fc;
	background-color: var(--background-color-darken);
	border-bottom: 1px solid #ebedf2;
	border-bottom: 1px solid var(--background-color-secondary);
}

.theme-dark button.toastui-editor-toolbar-icons {
	border: 1px solid var(--background-color-darken);
	background-position-y: -49px;
}

.toastui-editor-defaultUI-toolbar button {
	border: 1px solid #f7f9fc;
	border: 1px solid var(--background-color-darken);
}

.toastui-editor-toolbar-divider {
	background-color: #e1e3e9;
	background-color: var(--background-color-secondary);
}

.toastui-editor-ww-container {
	border-radius: 0px 0px 6px 6px;
	background-color: #fff;
	background-color: var(--input-color);
	color: var(--font-color);
}

.toastui-editor-mode-switch {
	background-color: #fff;
	background-color: var(--background-color-secondary);
	border-top: 1px solid #dadde6;
	border-top: none;
	padding-right: 1px;
}

.toastui-editor-mode-switch .tab-item {
	background: #f7f9fc;
	background: var(--background-color-darken);
	border: none;
}

.toastui-editor-mode-switch .tab-item.active {
	border-top: none;
	background-color: #fff;
	background-color: var(--background-color-lighten);
	color: #fff;
	color: var(--font-color);
}

.toastui-editor-main .toastui-editor-md-splitter {
	background-color: #ebedf2;
	background-color: var(--background-color-secondary);
}

.toastui-editor-popup {
	background: #fff;
	background-color: var(--background-color-secondary);
	border: 1px solid #dadde6;
	border: 1px solid var(--background-color);
	border-radius: 6px;
}

.toastui-editor-popup-body input[type="text"] {
	font-family: "Poppins-Light" !important;
	font-size: 0.9375rem !important;
	color: var(--font-color);
}

.toastui-editor-defaultUI button {
	border-radius: 10px;
}

.toastui-editor-defaultUI .toastui-editor-ok-button {
	background-color: #00a9ff;
	background-color: var(--color-primary);
	outline-color: #009bf2;
	outline-color: var(--color-primary);
	color: #fff;
}

.toastui-editor-popup-body label {
	color: #555;
	color: var(--font-color);
}

.toastui-editor-dropdown-toolbar {
	border: 1px solid #dadde6;
	background-color: #f7f9fc;
	background-color: var(--background-color-darken);
	border: 1px solid var(--background-color-secondary);
}

.toastui-editor-toolbar-icons.custom-header-btn {
	background-position-x: 3px;
}

.markdown-editor .help-button {
	background-color: var(--background-color-darken);
	color: var(--font-color);
	font-size: 18px;
	padding: 0px;
	font-family: "Poppins-Regular";
}

.markdown-editor .help-button:hover {
	color: grey;
}
