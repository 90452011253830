.Menu .PagesGroup.open>.pages-group {
    display: flex;
    flex-direction: column;
    align-items: center;
	gap: 0.3rem;
}

.Menu .PagesGroup.open .pages-group .page-button {
    height: 70px;
}

.Menu .PagesGroup .pages-group {
	display: none;
	margin-top: 0.3em;
	position: relative;
}

.Menu .PagesGroup .pages-group .menu-wire
{
	width: 2px;
	background-color: #fff;
	background-color: var(--background-color);
	background-color: var(--color-primary);
	position: absolute;
	align-self: baseline;
	z-index: 0;
	margin-top: -50%;
	margin-left: calc(50% - 1px);	
}

.PagesGroup .pages-group-arrow {
	transition-duration: 0.3s;
}

.PagesGroup.open .pages-group-arrow {
	transform: rotate(90deg);
}


/*
** -- Classic
*/
.classic-display.Menu .PagesGroup {
	width: 100%;
}

.classic-display.Menu .PagesGroup .menu-arrow {
	transition-duration: 0.5s;
}
.classic-display.Menu .PagesGroup.open .menu-arrow {
	transform: rotate(90deg);
}

.classic-display.Menu .PagesGroup.open>.pages-group {
	align-items: center;
	background-color: rgba(var(--color-primary-rgb),0.2);
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	overflow: hidden;
	padding: 5px;
	padding-left: 0.5rem;
}

.rounded-buttons .classic-display.Menu .PagesGroup.open>.pages-group {
	border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;
}

.classic-display.Menu .pages-group>* {
	width: 100%;
}

.classic-display.Menu .PagesGroup.active > .page-button,
.classic-display.Menu .page-button.active {
	border: 2px solid var(--color-primary);
	background-color: var(--color-primary);
	color: white !important;
	color: var(--color-primary-font-color) !important;
}

.classic-display.Menu .PagesGroup .pages-group .menu-wire {
    width: 2px;
    background-color: #fff;
    background-color: var(--background-color);
    background-color: var(--color-primary);
    position: absolute;
    align-self: baseline;
    z-index: 0;
    margin-top: -5px;
	margin-left: 0px;
	left: 1px;
}
