.accounts-menu {
	border-radius: 6px;
	color: var(--font-color);
}

.accounts-menu.open {
	background-color: var(--background-color);
	border-radius: 6px;
}

.account-menu {
	font-size: 0.75rem;
	line-height: 0.875rem;
	color: var(--font-color);
	border-radius: 10px;
}

.account-menu .Welcome {
	font-size: 0.75rem;
	line-height: 0.875rem;
	color: #888888;
}

.cur-account-menu {
	color: #888888;
	font-size: 0.625rem;
}

.cur-account-menu .user-image {
	background-color: var(--background-color-secondary);
}

.accounts-menu.open .cur-account-menu {
	color: var(--font-color);
}

.account-menu:not(.cur-account-menu):hover {
	background-color: rgba(0, 0, 0, 0.189);
}

.accounts-list .user-image {
	width: 30px;
	height: 30px;
}

.account-menu:not(:last-child) {
	margin-bottom: 0px;
}

.account-menu .company-name {
	color: #808080;
	text-transform: uppercase;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.menu-nav .accounts-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	flex-wrap: wrap;
}

.account-menu-body {
	margin: -60px -5px -5px;
	padding: 40px 10px 10px 10px;
	z-index: 0;
}

.account-menu-body > div,
.account-menu-body .account-menu-links > div {
	margin-top: 10px;
	padding-top: 10px;
	font-size: 0.875rem;
	line-height: 0.8125rem;
}

.account-menu-body > div:not(:first-child),
.account-menu-body .account-menu-links > div:not(:first-child) {
	border-top: 1px solid #cccccc;
	border-top: 1px solid var(--background-color-secondary);
}

.account-menu-body .accounts-list:empty {
	display: none;
}

.btn-disconnect:hover * {
	color: var(--font-color);
	text-decoration: underline;
}

.account-menu .show-account-menu-btn {
	font-size: 1rem;
	color: grey;
}
