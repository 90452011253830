.user-card .categories-cont {
	margin-top: auto;
}

.user-card .OnlineStatus {
	position: absolute;
	right: 0px;
	bottom: 0px;
	padding: 5px;
	border-radius: 50px;
	background-color: #00a900;
	border: 2px solid var(--background-color-secondary);
}

.user-site {
	text-transform: uppercase;
	font-size: 0.8125rem;
	line-height: 0.9375rem;
}

.user-fullname {
	font-size: 1.625rem;
	line-height: 1.875rem;
	text-transform: capitalize;
}

.user-contact-icon {
	color: rgb(181, 181, 181);
}

.user-whatsapp {
	font-size: 1.5rem;
	line-height: 1.5625rem;
}

.user-phone {
	font-size: 1.25rem;
	line-height: 1.25rem;
	margin-left: 10px;
}

.user-mail {
	font-size: 1.25rem;
	line-height: 1.5625rem;
}

.user-work-family,
.user-work-function {
	font-size: 0.75rem;
	line-height: 0.875rem;
}

.user-work-family {
	color: grey;
}

.user-card .category-label {
	line-height: 0.8125rem;
}

.user-card .SaveUserBtn {
	opacity: 0;
	transition-duration: 0.3s;
}

.SaveUserBtn:focus,
.user-card:hover .SaveUserBtn:not(.saved),
.SaveUserBtn.saved {
	opacity: 1;
}

/*
** -- groups
*/

.user-card .TagsList {
	max-height: 100px;
	overflow: auto;
}

/*
** -- Author preview
*/

.author-preview .user-image {
	width: 45px;
}

.author-preview .user-infos {
	font-size: 0.75rem;
	line-height: 0.8125rem;
	word-break: initial;
}

/*
** -- in table
*/
td .author-preview .user-infos {
	font-size: 0.7rem;
	line-height: 0.75rem;
}

.posts-list-author-preview .user-image {
	width: 25px;
}

.posts-list-author-preview .user-infos {
	font-size: 0.5rem;
	line-height: 0.6rem;
}

/*
** -- responsive
*/

.users-cont .user-card.tile {
	width: auto;
	max-height: 250px;
	margin: 0;
}

@media (max-width: 750px) {
	.users-cont .user-card.tile {
		width: 98%;
		max-height: 250px;
	}

	.user-tiles-scroll {
		gap: 1rem;
	}

	.users-cont .user-card.tile {
		max-height: unset;
		margin-left: 5px;
	}
}

@media (min-width: 750px) {
	.users-cont .user-card.tile {
		flex: 1 1 45%;
		max-height: unset;
	}
}

@media (min-width: 1250px) and (max-width: 1600px) {
	.users-cont .user-card.tile {
		flex: 1 1 24%;
	}
}

@media (min-width: 1600px) {
	.users-cont .user-card.tile {
		flex: 1 1 19%;
	}
}

@media (max-width: 700px) {
	.user-tiles-scroll {
		width: 103%;
		padding-right: 0rem;
		padding-bottom: 4rem;
	}
	.ContactsBody {
		padding: 0 5% 6rem 5%;
	}
}
