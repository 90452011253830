.StatsChartCanvas {
	display: flex;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	height: 50vh;
}

.StatsChartCanvas > canvas {
	width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
