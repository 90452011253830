
.map-cont {
	width: 100%;
	height: 100%;
	position: relative;
}

.map-cont .site-map
{
	height: 100%;
	border-radius: 25px;
}

.map-cont .site-popup {
	font-size: 0.75rem;
}

.map-cont .site-popup .title {
	font-size: 1.25rem;
	line-height:  1.25rem;
}


.map-cont .site-popup .site-logo
{
	width: 50px;
}

.map-cont .site-popup .site-infos {
	font-size: 0.75rem;
	line-height:  0.75rem;
}

.map-cont .site-popup .site-infos > * {
	margin-bottom: 5px;
}
