.IntroPopper {
	position: relative;
	height: 100%;
	color: var(--font-color);
	background-color: var(--background-color-secondary);
}

.IntroPopper .head {
	position: relative;
	width: 100%;
	height: 30vh;
	border-bottom: 2px solid rgba(var(--color-primary-rgb), 1);
}

.IntroPopper .head .bg {
	position: relative;
	width: 100%;
	height: 30vh;
	background-size: cover;
	background-position: center 10px;
	background-repeat: no-repeat;
	background-image: url("assets/OnlyAll-noLogo-transp-bw.png");
	opacity: 0.7;
}

.IntroPopper .head .site-logo {
	position: absolute;
	margin: auto;
	height: 100px;
	text-align: center;
	bottom: 0px;
	left: 50%;
	translate: -50% 50%;
	background-color: var(--background-color-secondary);
	padding: 5px 10px;
	border-radius: 12px;
	border: 2px solid rgba(var(--color-primary-rgb), 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.IntroPopper .body {
	padding: 20px;
	padding-top: 60px;
	color: var(--font-color);
}

.IntroPopper .title {
	font-size: 3rem;
	line-height: 3rem;
	font-family: "Poppins-ULight";
}

.IntroPopper .intro-text {
	font-size: 0.9rem;
	line-height: 1.1rem;
}

.IntroPopper .tuto-text {
	color: grey;
}
