.locales-menu-cont {
	display: flex;
}
.locales-item {
	cursor: pointer;
}

.locales-item:not(:last-child)
{
	margin-right: 1rem;
}

.locales-item a {
	display: flex;
	align-items: center;
}

.flag-icon {
	width: 20px;
	height: fit-content;
}
