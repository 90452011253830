.TileImage {
	width: 50%;
	aspect-ratio: 14/11;
}

.TileImage.loading > div {
	border-radius: 25px;
}

.TileImage .post-image {
	aspect-ratio: 14/11;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.TileImage.no-image {
	background-color: #f0f0f0;
}
