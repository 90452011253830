.image-preview {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 6px;
    margin: 5px;
    background-size: 85%;
    background-position: center;
    background-repeat: no-repeat;
	background-color: var(--background-color-secondary);
	overflow: hidden;
	user-select: none;
	position: relative;
}

.del-image-btn {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--background-color-secondary);
	width: 20px;
	height: 20px;
	line-height:  1.25rem;
	font-size: 0.75rem;
	text-align: center;
	border-bottom-right-radius: 3px;
	cursor: pointer;
	opacity: 0.75;
}

.image-items-placeholder {
	color: grey;
	position: absolute;
	left: 0px;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	height: 100%;
}
