* {
	--tile-margin: 12.5px;
	--tile-padding: 15px;
	--tile-borer-radius: 12px;
}

.tiles-cont {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0px calc(var(--tile-margin) * -1);
}

.Tile,
.tile,
.fixed-tile {
	cursor: pointer;
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	margin: var(--tile-margin);
	padding: var(--tile-padding);
	border-radius: var(--tile-borer-radius);
	word-break: break-word;
	color: var(--font-color);
	background-color: white;
	background-color: var(--tiles-bg-color);
	vertical-align: top;
	transition-duration: 0.3s;
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (max-width: 700px) {
	.tile:not(.fixed-tile) {
		width: calc(100% - calc(var(--tile-margin) * 2));
		min-width: auto;
		margin-right: 0px;
		margin-top: 0px;
	}
	.tiles-cont {
		padding-bottom: 7rem;
	}
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 700px) and (max-width: 1200px) {
	.tile:not(.fixed-tile) {
		width: calc(50% - calc(var(--tile-margin) * 2));
		min-width: auto;
	}
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) and (max-width: 1500px) {
	.tile:not(.fixed-tile) {
		width: calc((100% / 3) - calc(var(--tile-margin) * 2));
		min-width: calc((100% / 3) - calc(var(--tile-margin) * 2));
	}
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1500px) {
	.tile:not(.fixed-tile) {
		width: calc((100% / 4) - calc(var(--tile-margin) * 2));
		min-width: calc((100% / 4) - calc(var(--tile-margin) * 2));
	}
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1700px) {
	.tile:not(.fixed-tile) {
		width: calc((100% / 5) - calc(var(--tile-margin) * 2));
		min-width: calc((100% / 5) - calc(var(--tile-margin) * 2));
	}
}

@media (min-width: 2300px) {
	.tile:not(.fixed-tile) {
		width: calc((100% / 6) - calc(var(--tile-margin) * 2));
		min-width: calc((100% / 6) - calc(var(--tile-margin) * 2));
	}
}
