.SearchBar {
	display: flex;
	align-items: center;
	width: fit-content;
	background-color: var(--background-color-secondary);
	border-radius: 10px;
	padding: 0px 5px;
	position: relative;
}

.rounded-buttons .SearchBar {
	border-radius: 1000px;
}

.SearchBar input {
	width: 100%;
	background-color: transparent;
	padding-right: 20px;
}

.SearchBar .search-btn {
	background-color: transparent;
	border: none;
	color: grey;
	align-items: center;
	justify-content: center;
	display: flex;
}

.SearchBar .clear-field {
	position: absolute;
	background-color: #99999957;
	font-size: 0.625rem;
	min-width: 15px;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 300px;
	line-height: 0;
	letter-spacing: 0;
	color: grey;
	right: 35px;
	cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
