.TableToolBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--background-color-secondary);
	padding: 0.25rem;
	border-radius: 6px;
}

.TableToolBar .checkbox:not(:checked) {
	border: 1px solid var(--color-primary);
}

.TableToolBar .sep {
	width: 1px;
	height: auto;
	background-color: var(--background-color);
	display: flex;
}

.TableToolBar .TagLabel {
	font-size: 0.75rem;
	padding: 0.3rem;
	border-radius: 10px;
}

@media screen and (max-width: 600px) {
	.TableToolBar .btn-title {
		display: none;
	}
}

/* Button Popper */

.TableToolBar .ToolbarButton {
	display: flex;
}

.TableToolBar .popper-overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	display: flex;
	padding: 5vh 0px;
	justify-content: center;
	z-index: 3;
	overflow: auto;
}

.TableToolBar .popper-cont {
	padding: 10px;
	border-radius: 6px;
	background-color: var(--background-color);
	box-shadow: 0px 0px 6px 0px var(--shadow);
	width: 90%;
	position: relative;
	display: flex;
	flex-direction: column;
	height: fit-content;
}

.TableToolBar .popper-cont .popper-inner-cont {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.TableToolBar .popper-cont .popper-load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(var(--background-color-rgb), 0.7);
}
