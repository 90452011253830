.CompaniesPage {
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.CompaniesPage .preview-drawer > div:not(.close-preview-drawer-btn) {
	padding: 0px;
	padding-top: 0px;
}

.CompaniesPage .close-preview-drawer-btn {
	z-index: 12;
}

.CompaniesPage .CompanyPage .infos-cont {
	padding-left: 30px;
}

.CompaniesPage .CompanyPage .NestedPagesMenu {
	position: sticky;
	top: 0px;
	padding-left: 30px;
}
