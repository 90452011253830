.cards-container {
	overflow-x: scroll;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 1rem;
}

.cards-container > div {
	width: 100%;
}

.cards-container .post-text:before {
	content: "";
	background: linear-gradient(0deg, var(--tiles-bg-color) 20%, #0000);
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	color: #808080;
	font-size: 0.75rem;
	max-height: 100px;
}
