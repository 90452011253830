.MostLikedPosts {
	padding: 12px;
	border-radius: 12px;
	background-color: var(--background-color-secondary);
}

.MostLikedPosts .head-btns {
	display: flex;
	align-items: center;
	background-color: var(--background-color);
	border-radius: 6px;
	overflow: hidden;
}

.rounded-buttons .MostLikedPosts .head-btns {
	border-radius: 50px;
}

.MostLikedPosts .head-btns > button {
	padding: 5px 10px;
	width: 100%;
}

.MostLikedPosts .head-btns > button:not(:last-child)
{
	border-right: 1px solid var(--background-color-secondary);
}

.MostLikedPosts .head-btns > button.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.MostLikedPosts .list-ph {
	color: grey;
	text-align: center;
}

.LikedPostsList {
	display: flex;
	flex-direction: column;
	max-height: 30vh;
	overflow: auto;
}

/*
** -- MostLikedPostTile
*/

.MostLikedPostTile {
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 5px;
	font-size: 0.875rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.MostLikedPostTile:not(:last-child)
{
	margin-bottom: 0.5rem;
}

.MostLikedPostTile .TileImage
{
	height: 50px;
	width: 100px;
}

.MostLikedPosts .TileImage .post-image {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.MostLikedPostTile .tile-text {
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: keep-all;
	overflow: hidden;
}
